import useCurrentUser from "@/hooks/useCurrentUser";
import useEnrollmentPatient from "@/hooks/useEnrollmentPatient";
import usePayers from "@/hooks/usePayers";
import usePrimaryCareProvs from "@/hooks/usePrimaryCareHomes";
// import bpReadingSchema from "@/resolvers/bpReading";
import {
  Modal,
  ModalBody,
  ModalContent,
  // ModalFooter,
  ModalHeader,
  ModalOverlay,
  StepIndicator,
  Stepper,
  Step,
  useSteps,
  StepStatus,
  StepNumber,
  StepIcon,
  Box,
  StepTitle,
  StepDescription,
  ModalCloseButton,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Button,
  Center,
  FormErrorMessage,
  SimpleGrid,
  Select,
  Checkbox,
  Textarea,
  Text,
  useToast,
  Spinner,
  Tooltip,
  Card,
  IconButton,
  AlertDialog,
  AlertDialogOverlay,
  useDisclosure,
  AlertDialogContent,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from "@chakra-ui/react";
// import { yupResolver } from "@hookform/resolvers/yup";
import {
  ClientType,
  ContactMethod,
  Race,
  Sex,
  SourceForCHWFunds,
  UserType
} from "@oben-core-web/constants/core-enums";
import { BpReading } from "@oben-core-web/models/bp-reading";
import { ClientNote, ClientNoteType } from "@oben-core-web/models/client-note";
import { ClientUser } from "@oben-core-web/models/client-user";
import { ScreeningClient } from "@oben-core-web/models/screening-client";
import { ClientNoteService } from "@oben-core-web/services/client-note-service";
import { ClientUserService } from "@oben-core-web/services/client-user-service";
// import { ClientUserService } from "@oben-core-web/services/client-user-service";
import { DateTime } from "luxon";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import PatientOutreacher from "../molecules/PatientOutreacher";
import {
  Outreach,
  OutreachPurpose,
  OutreachStatus
} from "@oben-core-web/models/outreach";
import { OutreachService } from "@oben-core-web/services/outreach-service";
import useBarbers from "@/hooks/useBarbers";
import useBarbershops from "@/hooks/useBarbershops";
import { TbTrash } from "react-icons/tb";
import useBarber from "@/hooks/useBarber";
import {
  InternalTask,
  InternalTaskType,
  TaskCreatorType
} from "@oben-core-web/models/internal-task";
import { WebUserService } from "@oben-core-web/services/web-user-service";
import { InternalTaskService } from "@oben-core-web/services/internal-task-service";
import { PrimaryCareProv } from "@oben-core-web/models/primary-care-prov";
import { MdCheckCircleOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { BarberUserService } from "@oben-core-web/services/barber-user-service";
import { BarbershopService } from "@oben-core-web/services/barbershop-service";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import { BillingTransactionService } from "@oben-core-web/services/billing-transaction-service";
import { WebUser } from "@oben-core-web/models/web-user";
import { Barbershop } from "@oben-core-web/models/barbershop";
import { BarberUser } from "@oben-core-web/models/barber-user";

interface IEnrollmentWizard {
  isOpen: boolean;
  onToggle: () => void;
  patientId: string; // screeningClientId or clientUserId;
  patientType: ClientType;
}

interface IEnrollmentWizardForm {
  bpVerification: null | BpReading;
  patientInformation: null | ClientUser;
  // patientInformation: null | {
  //   sex: "Male" | "Female";
  //   ethnicity: "Black" | "White" | "Hispanic" | "Asian";
  //   dob: string;
  //   primaryCareProvId: string;
  //   hypertensionDiagonises: boolean;
  //   email: string;
  //   phoneNumber: string;
  //   prefContactMethod: ContactMethod;
  //   payerId: string;
  // };
  enrollmentInformation: null | {
    insuranceNumber: string;
    sourceForCHWFunds: SourceForCHWFunds;
    // primaryCarePhysician: string;
    prefApptTimes: string[];
    prefBarberId: string;
    prefBarbershopId: string;
    chartPrepNotes: string;
  };
}

const EnrollmentWizard = ({
  isOpen,
  onToggle,
  patientId,
  patientType
}: IEnrollmentWizard) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { currentUser } = useCurrentUser();
  const { enrollmentPatient, fetchEnrollmentPatient } = useEnrollmentPatient({
    patientId,
    patientType,
    fetchOnMount: true
  });
  const [enrollmentOutreach, setEnrollmentOutreach] = useState<Outreach>();
  const [internalTask, setInternalTask] = useState<InternalTask>();
  const [wasOutreachAttemptLogged, setWasOutreachAttemptLogged] =
    useState(false);
  const [formValues, setFormValues] = useState<IEnrollmentWizardForm>({
    bpVerification: enrollmentPatient?.currentBpReading ?? null,
    patientInformation: enrollmentPatient
      ? ClientUser.fromMap(patientId, enrollmentPatient.toJson())
      : null,
    enrollmentInformation: null
  });

  useEffect(() => {
    const fetchEnrollmentOutreach = async () => {
      const outreachService = new OutreachService();
      const clientOutreaches = await outreachService.getClientOutreaches(
        patientId
      );
      const enrollmentOutreaches = clientOutreaches
        .filter((o) => o.purpose === OutreachPurpose.NewEnrollment)
        .sort((a, b) => (a.createdDate < b.createdDate ? -1 : 1));
      setEnrollmentOutreach(enrollmentOutreaches[0]);
    };
    fetchEnrollmentOutreach();
  }, [patientId]);

  const { activeStep, goToNext, goToPrevious } = useSteps({
    count: 5
    // index: 4
  });

  const setFormStepData = useCallback(
    async (
      formKey: keyof IEnrollmentWizardForm,
      data: Partial<IEnrollmentWizardForm>
    ) => {
      setFormValues((formData) => ({ ...formData, [formKey]: data[formKey] }));
      if (patientId && patientType === ClientType.ClientUser) {
        if (formKey === "patientInformation" && data.patientInformation) {
          const client = ClientUser.fromMap(
            patientId,
            data.patientInformation.toJson()
          );
          const clientUserService = new ClientUserService();
          await clientUserService.updateClientUser(client);
          await fetchEnrollmentPatient();
          goToNext();
          return;
        }

        if (formKey === "enrollmentInformation" && data.enrollmentInformation) {
          const {
            insuranceNumber,
            sourceForCHWFunds,
            // primaryCarePhysician,
            prefApptTimes,
            prefBarberId,
            prefBarbershopId,
            chartPrepNotes
          } = data.enrollmentInformation;
          if (chartPrepNotes) {
            const clientNoteService = new ClientNoteService();
            const note = new ClientNote({
              modelId: "",
              clientId: patientId,
              authorId: currentUser!.uid,
              authorType: currentUser!.userType,
              createdDate: new Date(),
              noteSourceId: patientId,
              noteType: ClientNoteType.General,
              summary: "Chart prep note",
              details: data.enrollmentInformation.chartPrepNotes
            });
            await clientNoteService.addClientNote(note);
          }
          const patient = await fetchEnrollmentPatient();
          const clientUserService = new ClientUserService();
          const client = ClientUser.fromMap(patientId, {
            ...patient.toJson(),
            payerPolicyNumber: insuranceNumber,
            sourceForCHWFunds,
            // primaryCarePhysician,
            prefApptTimes,
            prefBarberId,
            prefBarbershopId
          });
          await clientUserService.updateClientUser(client);
          await fetchEnrollmentPatient();
          goToNext();
          return;
        }
      }
      goToNext();
    },
    [currentUser, fetchEnrollmentPatient, goToNext, patientId, patientType]
  );

  const steps = useMemo(
    () => [
      {
        title: "Enrollment Outreach",
        description: "Log Enrollment Outreach Attempt",
        Component: (
          <VStack w={"full"} spacing={0}>
            {enrollmentPatient && (
              <PatientOutreacher
                clientId={(enrollmentPatient as ClientUser)!.uid}
                patientOutreach={enrollmentOutreach}
                outreachPurpose={OutreachPurpose.NewEnrollment}
                onOutreachLogged={() => setWasOutreachAttemptLogged(true)}
                onOutreachStatusChanged={(status: OutreachStatus) => {
                  if (status === OutreachStatus.Canceled) {
                    onToggle();
                  }
                }}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
                columns={2}
                spacing={2}
                w={"full"}
                my={3}
                historyContainerProps={{
                  maxHeight: "70vh",
                  overflowY: "scroll"
                }}
                // historyContainerProps={{ maxHeight: "57.5vh", overflowY: "scroll" }}
              />
            )}
            <HStack
              w={"full"}
              alignItems={"center"}
              justifyContent={"space-between"}
              position={"absolute"}
              bottom={0}
            >
              <Button onClick={onToggle}>Cancel</Button>
              <Tooltip
                label={
                  wasOutreachAttemptLogged
                    ? "Begin Enrollment"
                    : "Must log a successful outreach attempt first"
                }
              >
                <Button
                  colorScheme='blue'
                  onClick={goToNext}
                  isDisabled={!wasOutreachAttemptLogged}
                >
                  Next
                </Button>
              </Tooltip>
            </HStack>
          </VStack>
        )
      },
      {
        title: "Patient Information",
        description: "Collect patient information",
        Component: (
          <PatientInformationStep
            enrollmentPatient={enrollmentPatient!}
            // onPrev={goToPrevious}
            onNext={goToNext}
            onFormSubmit={setFormStepData}
          />
        )
      },
      {
        title: "Enrollment Information",
        description: "Collect enrollment information",
        Component: (
          <>
            {enrollmentPatient ? (
              <EnrollmentInformationStep
                enrollmentClient={enrollmentPatient}
                placeBasedCareProvId={currentUser!.placeBasedCareProvId ?? ""}
                onPrev={goToPrevious}
                onNext={goToNext}
                onFormSubmit={setFormStepData}
              />
            ) : (
              <Spinner />
            )}
          </>
        )
      },
      {
        title: "Summary",
        description: "Submit enrollment information",
        Component: (
          <>
            {enrollmentPatient && currentUser && (
              <EnrollmentSummaryStep
                currentUser={currentUser}
                enrollmentData={formValues}
                enrollmentPatient={enrollmentPatient}
                onPrev={goToPrevious}
                onNext={async () => {
                  // update outreach status to completed
                  if (enrollmentOutreach) {
                    const outreachService = new OutreachService();
                    enrollmentOutreach.status = OutreachStatus.Completed;
                    await outreachService.updateOutreach(enrollmentOutreach);
                  }
                  // find assignee for internal task
                  const webUserService = new WebUserService();
                  const scheduleAssignee = (
                    await webUserService.getPlaceBasedCareProvWebUsers(
                      currentUser!.placeBasedCareProvId ?? ""
                    )
                  ).filter((wu) => wu.userType === UserType.ProgramManager);
                  // create internal task to schedule
                  const internalTask = new InternalTask({
                    id: "",
                    placeBasedCareProvId:
                      currentUser!.placeBasedCareProvId ?? "",
                    internalTaskType: InternalTaskType.ScheduleAppointment,
                    name: `Appointment Scheduling For ${enrollmentPatient.name.fullName}`,
                    description:
                      "Schedule appointment after enrollment completion",
                    clientId: (enrollmentPatient as ClientUser).uid,
                    assigneeId:
                      currentUser!.userType === UserType.ProgramManager
                        ? currentUser!.uid
                        : scheduleAssignee[0]
                        ? scheduleAssignee[0].uid
                        : "",
                    creatorId: currentUser!.uid,
                    creatorType: TaskCreatorType.WebUser,
                    createdDate: new Date(),
                    dueDate: DateTime.now().plus({ days: 1 }).toJSDate(),
                    completionDate: null
                  });
                  const internalTaskService = new InternalTaskService();
                  await internalTaskService
                    .addTask(internalTask)
                    .then((taskId) => {
                      internalTask.id = taskId;
                      setInternalTask(internalTask);
                    });
                  goToNext();
                }}
              />
            )}
          </>
        )
      }
    ],
    [
      currentUser,
      enrollmentOutreach,
      enrollmentPatient,
      formValues,
      goToNext,
      goToPrevious,
      onToggle,
      setFormStepData,
      wasOutreachAttemptLogged
    ]
  );
  if (!enrollmentPatient) {
    return (
      <Center h={"full"} w={"full"}>
        <Spinner />
      </Center>
    );
  }
  return (
    <Modal isOpen={isOpen} onClose={onToggle} size={"full"}>
      <ModalOverlay />
      <ModalContent p={2}>
        <ModalHeader>
          <ModalCloseButton />
          <Heading>{enrollmentPatient.name.fullName} Enrollment</Heading>
          {enrollmentPatient.dob && (
            <Heading
              fontSize={"medium"}
              color={"gray.500"}
              fontWeight={"normal"}
            >
              DOB:{" "}
              {DateTime.fromJSDate(enrollmentPatient.dob)
                .toUTC()
                .toFormat("MM/dd/yyyy")}
            </Heading>
          )}
        </ModalHeader>
        {activeStep <= 3 ? (
          <ModalBody>
            <Stepper index={activeStep}>
              {steps.map(({ title, description }, index) => {
                return (
                  <Step key={`enrollment-step-${index}`}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box>
                      <StepTitle>{title}</StepTitle>
                      <StepDescription>{description}</StepDescription>
                    </Box>
                  </Step>
                );
              })}
            </Stepper>
            <Box
              minH={"80vh"}
              h={"full"}
              py={2}
              // border={"1px solid blue"}
              display={"flex"}
              position={"relative"}
              // flex={"1 1 0"}
            >
              {steps[activeStep].Component && steps[activeStep].Component}
            </Box>
          </ModalBody>
        ) : (
          <ModalBody>
            <VStack
              justify={"center"}
              h={"80vh"}
              w={"full"}
              flexDir={"column"}
              spacing={3}
            >
              <MdCheckCircleOutline size={36} color='teal' />
              <Text
                as={"b"}
                fontSize={"x-large"}
                color={"teal"}
              >{`${enrollmentPatient.name.fullName} has been enrolled`}</Text>
              <Button
                colorScheme={"teal"}
                onClick={async () => {
                  if (!internalTask) {
                    toast({
                      status: "error",
                      description: "Unable to schedule right now"
                    });
                  }
                  if (enrollmentPatient instanceof ClientUser) {
                    let prefBarber: BarberUser | undefined = undefined;
                    let prefBarbershop: Barbershop | undefined = undefined;
                    if (enrollmentPatient.prefBarberId) {
                      const barberService = new BarberUserService();
                      prefBarber = await barberService.getBarberUser(
                        enrollmentPatient.prefBarberId ?? ""
                      );
                    }
                    if (enrollmentPatient.prefBarbershopId) {
                      const barbershopService = new BarbershopService();
                      prefBarbershop = await barbershopService.getBarbershop(
                        enrollmentPatient.prefBarbershopId ?? ""
                      );
                    }
                    navigate("/appointments", {
                      state: {
                        from: "/?page=scheduling",
                        clientId: enrollmentPatient.uid,
                        clientName: enrollmentPatient.name,
                        ...(prefBarber ? { prefBarber } : {}),
                        ...(prefBarbershop ? { prefBarbershop } : {}),
                        internalTask: internalTask
                      },
                      replace: true
                    });
                  }
                }}
              >
                Schedule Appointment
              </Button>
              <Button variant={"link"} onClick={onToggle}>
                Schedule Later
              </Button>
            </VStack>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

export default EnrollmentWizard;

// const BPVerificationStep = ({
//   currentBpReading,
//   onFormSubmit,
//   onNext
// }: {
//   currentBpReading: BpReading | null;
//   onFormSubmit: (
//     formKey: keyof IEnrollmentWizardForm,
//     data: Partial<IEnrollmentWizardForm>
//   ) => void;
//   onNext: () => void;
// }) => {
//   const [bpVerificationRequired, setBPVerificationRequired] = useState(false);
//   const { register, formState, handleSubmit } = useForm<iBpReadingData>({
//     defaultValues: {
//       ...currentBpReading
//     },
//     resolver: yupResolver(bpReadingSchema) as any
//   });

//   const onSubmit = async (data: iBpReadingData) => {
//     if (formState.isDirty) {
//       console.log("Bp Form was dirtied");
//       const validationReading = BpReading.fromMap("", data);
//       onFormSubmit("bpVerification", { bpVerification: validationReading });
//     } else {
//       onNext();
//     }
//   };
//   return (
//     <Box border={"1px solid"} w={"full"} h={"full"}>
//       {!bpVerificationRequired ? (
//         <VStack>
//           <Heading as={"h2"} fontSize={"xl"}>
//             Does this patient require BP Verification?
//           </Heading>
//           <HStack spacing={4}>
//             <Button onClick={onNext}>No</Button>
//             <Button onClick={() => setBPVerificationRequired(true)}>Yes</Button>
//           </HStack>
//         </VStack>
//       ) : (
//         <form onSubmit={handleSubmit(onSubmit)}>
//           <VStack spacing={4}>
//             <Heading as='h2' fontSize='xl'>
//               Enter Blood Pressure Reading
//             </Heading>
//             <FormControl isInvalid={!!formState.errors.systolic}>
//               <FormLabel>Systolic</FormLabel>
//               <Input type='number' {...register("systolic")} />
//               <FormErrorMessage>
//                 {formState.errors.systolic?.message}
//               </FormErrorMessage>
//             </FormControl>
//             <FormControl isInvalid={!!formState.errors.diastolic}>
//               <FormLabel>Diastolic</FormLabel>
//               <Input type='number' {...register("diastolic")} />
//               <FormErrorMessage>
//                 {formState.errors.diastolic?.message}
//               </FormErrorMessage>
//             </FormControl>
//             <FormControl isInvalid={!!formState.errors.heartRate}>
//               <FormLabel>Heart Rate</FormLabel>
//               <Input type='number' {...register("heartRate")} />
//               <FormErrorMessage>
//                 {formState.errors.heartRate?.message}
//               </FormErrorMessage>
//             </FormControl>
//             <HStack>
//               <Button onClick={() => setBPVerificationRequired(false)}>
//                 Cancel
//               </Button>
//               <Button type='submit' isDisabled={!formState.isValid}>
//                 Submit
//               </Button>
//             </HStack>
//           </VStack>
//         </form>
//       )}
//     </Box>
//   );
// };

interface IPatientInformationForm {
  name: {
    first: string;
    last: string;
    display: string;
  };
  dob: Date | null;
  phoneNumber: string;
  email: string;
  sex: Sex;
  race: Race;
  primaryCareProvId: string;
  payerId: string;
  isTreatedForHypertension: boolean;
  prefContactMethod: ContactMethod;
}

const PatientInformationStep = ({
  enrollmentPatient,
  // onPrev,
  onNext,
  onFormSubmit
}: {
  enrollmentPatient: ClientUser | ScreeningClient;
  // onPrev: () => void;
  onNext: () => void;
  onFormSubmit: (
    formKey: keyof IEnrollmentWizardForm,
    data: Partial<IEnrollmentWizardForm>
  ) => Promise<void>;
}) => {
  const { primaryCareProvs } = usePrimaryCareProvs();
  const { payers } = usePayers();
  const { register, formState, handleSubmit, watch } =
    useForm<IPatientInformationForm>({
      defaultValues: {
        name: {
          first: enrollmentPatient.name.first ?? "",
          last: enrollmentPatient.name.last ?? "",
          display: enrollmentPatient.name.display ?? ""
        },
        dob: enrollmentPatient.dob,
        phoneNumber: enrollmentPatient.phoneNumber ?? "",
        email: enrollmentPatient.email ?? "",
        sex: (enrollmentPatient as ClientUser).sex ?? Sex.Unknown,
        race: (enrollmentPatient as ClientUser).race ?? Race.Unknown,
        primaryCareProvId:
          (enrollmentPatient as ClientUser).primaryCareProvId ?? "",
        payerId: (enrollmentPatient as ClientUser).payerId ?? "",
        isTreatedForHypertension: (enrollmentPatient as ClientUser)
          .isTreatedForHypertension,
        prefContactMethod:
          (enrollmentPatient?.prefContactMethod as ContactMethod) ?? null
      }
    });
  const dobString = DateTime.fromJSDate(
    watch("dob") ? new Date(watch("dob")!) : new Date()
  )
    .toUTC()
    .toFormat("yyyy-MM-dd");
  const onSubmit = async (data: IPatientInformationForm) => {
    if (formState.isDirty) {
      if (enrollmentPatient instanceof ClientUser) {
        const client = ClientUser.fromMap(enrollmentPatient.uid, {
          ...enrollmentPatient.toJson(),
          ...data
        });
        onFormSubmit("patientInformation", {
          patientInformation: client
        });
      }
    } else {
      onNext();
    }
  };

  formState.isDirty; // this is here so that dirty state is updated.  very annoying

  return (
    // <Box minH={"50vh"} h={"full"} flex={1} w={"full"} border={"1px solid red"}>
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        paddingTop: "1rem",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <SimpleGrid columns={3} gap={4} w={"full"} h={"full"}>
        <FormControl isInvalid={!!formState.errors.name?.first}>
          <FormLabel>First Name</FormLabel>
          <Input {...register("name.first")} />
          <FormErrorMessage>
            {formState.errors.name?.first?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!formState.errors.name?.last}>
          <FormLabel>Last Name</FormLabel>
          <Input {...register("name.last")} />
          <FormErrorMessage>
            {formState.errors.name?.last?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!formState.errors.name?.display}>
          <FormLabel>Display Name</FormLabel>
          <Input {...register("name.display")} />
          <FormErrorMessage>
            {formState.errors.name?.display?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formState.errors.dob}>
          <FormLabel>Date of Birth</FormLabel>
          <Input {...register("dob")} value={dobString} type={"date"} />
          <FormErrorMessage>{formState.errors.dob?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!formState.errors.sex}>
          <FormLabel>Sex</FormLabel>
          <Select {...register("sex")} placeholder='Make a selection'>
            <option value={Sex.Male}>Male</option>
            <option value={Sex.Female}>Female</option>
            <option value={Sex.Intersex}>Intersex</option>
          </Select>
          <FormErrorMessage>{formState.errors.sex?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!formState.errors.race}>
          <FormLabel>Race</FormLabel>
          <Select {...register("race")} placeholder='Make a selection'>
            <option value={Race.Black}>Black</option>
            <option value={Race.White}>White</option>
            <option value={Race.Other}>Other</option>
          </Select>
          <FormErrorMessage>{formState.errors.race?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formState.errors.phoneNumber}>
          <FormLabel>Primary Phone</FormLabel>
          <Input {...register("phoneNumber")} />
          <FormErrorMessage>
            {formState.errors.phoneNumber?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!formState.errors.prefContactMethod}>
          <FormLabel>Preferred Contact Method</FormLabel>
          <Select
            {...register("prefContactMethod")}
            placeholder='Make a selection'
          >
            <option>{ContactMethod.Phone}</option>
            <option>{ContactMethod.SMS}</option>
            <option>{ContactMethod.Email}</option>
          </Select>
          <FormErrorMessage>
            {formState.errors.prefContactMethod?.message}
          </FormErrorMessage>
        </FormControl>
        <div />

        {primaryCareProvs?.length && (
          <FormControl isInvalid={!!formState.errors.primaryCareProvId}>
            <FormLabel>Primary Care Home</FormLabel>
            <Select placeholder='None' {...register("primaryCareProvId")}>
              {primaryCareProvs?.map((careHome) => (
                <option
                  key={`care-home-option-${careHome.id}`}
                  value={careHome.id}
                >
                  {careHome.businessName}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {formState.errors.primaryCareProvId?.message}
            </FormErrorMessage>
          </FormControl>
        )}
        {payers?.length && (
          <FormControl isInvalid={!!formState.errors.payerId}>
            <FormLabel>Insurance Provider</FormLabel>
            <Select placeholder='None' {...register("payerId")}>
              {payers?.map((payer) => (
                <option key={`payer-option-${payer.id}`} value={payer.id}>
                  {payer.businessName}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {formState.errors.payerId?.message}
            </FormErrorMessage>
          </FormControl>
        )}
        <FormControl
          isInvalid={!!formState.errors.isTreatedForHypertension}
          display={"flex"}
          alignItems={"center"}
        >
          <FormLabel>Prior Hypertension Diagonsis</FormLabel>
          <Checkbox
            checked={
              enrollmentPatient instanceof ScreeningClient
                ? enrollmentPatient.hypertensionDiagnosis ?? false
                : enrollmentPatient instanceof ClientUser
                ? enrollmentPatient.isTreatedForHypertension ?? false
                : false
            }
            {...register("isTreatedForHypertension")}
          />
          <FormErrorMessage>
            {formState.errors.isTreatedForHypertension?.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <HStack
        spacing={4}
        position={"absolute"}
        right={0}
        bottom={0}
        w={"full"}
        justifyContent={"space-between"}
      >
        {/* <Button onClick={onPrev}>Previous</Button> */}
        <div />
        <Button type={"submit"} colorScheme='blue'>
          Next
        </Button>
      </HStack>
    </form>
    // </Box>
  );
};

interface IEnrollmentInfoData {
  insuranceNumber: string;
  primaryCarePhysician: string;
  chartPrepNotes: string;
  prefBarberId: string;
  prefBarbershopId: string;
  prefApptTimes: Array<{ value: string }>;
  sourceForCHWFunds: SourceForCHWFunds;
}

const EnrollmentInformationStep = ({
  enrollmentClient,
  placeBasedCareProvId,
  onPrev,
  onNext,
  onFormSubmit
}: {
  enrollmentClient: ClientUser | ScreeningClient;
  placeBasedCareProvId: string;
  onPrev: () => void;
  onNext: () => void;
  onFormSubmit: (
    formKey: keyof IEnrollmentWizardForm,
    data: Partial<IEnrollmentWizardForm>
  ) => void;
}) => {
  const { barbers } = useBarbers(placeBasedCareProvId);
  const { barbershops } = useBarbershops();
  const { handleSubmit, register, formState, control, setValue } =
    useForm<IEnrollmentInfoData>({
      defaultValues: {
        insuranceNumber:
          enrollmentClient instanceof ClientUser
            ? enrollmentClient.payerPolicyNumber ?? ""
            : "",
        // primaryCarePhysician: enrollmentClient.primaryCareProvId ?? "",
        chartPrepNotes: "",
        prefBarberId:
          enrollmentClient instanceof ClientUser &&
          enrollmentClient.prefBarberId
            ? enrollmentClient.prefBarberId
            : enrollmentClient.currentBpReading?.recorderType ===
              UserType.Barber
            ? enrollmentClient.currentBpReading.recorderId
            : "",
        prefBarbershopId:
          enrollmentClient instanceof ClientUser &&
          enrollmentClient.prefBarbershopId
            ? enrollmentClient.prefBarbershopId
            : enrollmentClient.currentBpReading?.recorderType ===
              UserType.Barber
            ? enrollmentClient.currentBpReading.locationId
            : "",
        prefApptTimes:
          enrollmentClient instanceof ClientUser
            ? enrollmentClient.prefApptTimes.map((a) => ({ value: a }))
            : [],
        sourceForCHWFunds:
          enrollmentClient instanceof ClientUser &&
          enrollmentClient.sourceForCHWFunds
            ? enrollmentClient.sourceForCHWFunds
            : SourceForCHWFunds.None
      }
    });

  useEffect(() => {
    const fetchChartPrepNote = async () => {
      if (enrollmentClient instanceof ClientUser) {
        const clientNoteService = new ClientNoteService();
        const chartPrepNote = (
          await clientNoteService.getAllClientNotes(enrollmentClient.uid)
        ).find((n) => n.noteType === ClientNoteType.ChartPrep);
        if (chartPrepNote) {
          setValue("chartPrepNotes", chartPrepNote?.details);
        }
      }
    };
    fetchChartPrepNote();
  }, [enrollmentClient, setValue]);

  const { fields, append, remove } = useFieldArray<
    IEnrollmentInfoData,
    "prefApptTimes"
  >({
    control,
    name: "prefApptTimes"
  });

  const onSubmit = async (data: IEnrollmentInfoData) => {
    if (formState.isDirty) {
      onFormSubmit("enrollmentInformation", {
        enrollmentInformation: {
          ...data,
          prefApptTimes: data.prefApptTimes.map((p) => p.value)
        }
      });
    } else {
      onNext();
    }
  };
  formState.isDirty;
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
        paddingTop: ".5rem"
      }}
    >
      <Card variant={"outline"} p={4}>
        <Heading as={"h3"} fontSize={"md"}>
          Enrollment Details
        </Heading>
        <HStack spacing={4} w={"full"} mt={2}>
          <VStack w={"75%"}>
            <HStack w={"full"}>
              <FormLabel w={"30%"}>Insurance Number</FormLabel>
              <FormControl w={"70%"}>
                <Input {...register("insuranceNumber")} />
                <FormErrorMessage>
                  {formState.errors?.insuranceNumber?.message}
                </FormErrorMessage>
              </FormControl>
            </HStack>

            {/* <HStack w={"full"}>
              <FormLabel w={"30%"}>Primary Care Physician</FormLabel>
              <FormControl w={"70%"}>
                <Input {...register("primaryCarePhysician")} />
                <FormErrorMessage>
                  {formState.errors?.primaryCarePhysician?.message}
                </FormErrorMessage>
              </FormControl>
            </HStack> */}
            <HStack w={"full"}>
              <FormLabel w={"30%"} alignSelf={"flex-start"}>
                Chart Prep Notes
              </FormLabel>
              <FormControl w={"70%"}>
                <Textarea {...register("chartPrepNotes")} />
                <FormErrorMessage>
                  {formState.errors?.chartPrepNotes?.message}
                </FormErrorMessage>
              </FormControl>
            </HStack>
          </VStack>
          <FormControl w={"25%"} alignSelf={"flex-start"}>
            <FormLabel>Eligible For CHW Benefits</FormLabel>
            <Select
              placeholder='Make a selection'
              {...register("sourceForCHWFunds")}
            >
              <option value={SourceForCHWFunds.None}>{"No"}</option>
              <option value={SourceForCHWFunds.Payer}>{"Yes"}</option>
            </Select>
            <FormErrorMessage>
              {formState.errors?.sourceForCHWFunds?.message}
            </FormErrorMessage>
          </FormControl>
        </HStack>
      </Card>
      <Card variant={"outline"} p={4} gap={2}>
        <Heading as={"h3"} fontSize={"md"}>
          Patient Preferences
        </Heading>
        {barbershops.length && (
          <HStack>
            <FormLabel w={"20%"}>Barbershop</FormLabel>
            <Select
              w={"50%"}
              placeholder='Select Preferred Barbershop'
              {...register("prefBarbershopId")}
            >
              {barbershops.map((bs) => (
                <option
                  key={`enrollment-wizard-barbershop-${bs.id}`}
                  value={bs.id}
                >
                  {bs.businessName}
                </option>
              ))}
            </Select>
            <div style={{ width: "25%" }} />
          </HStack>
        )}
        {barbers?.length && (
          <HStack>
            <FormLabel w={"20%"}>Barber</FormLabel>
            <Select
              w={"50%"}
              placeholder='Select Preferred Barber'
              {...register("prefBarberId")}
            >
              {barbers?.map((b) => (
                <option key={`enrollment-wizard-barber-${b.uid}`} value={b.uid}>
                  {b.name.display}
                </option>
              ))}
            </Select>
            <div style={{ width: "25%" }} />
          </HStack>
        )}
        <HStack w={"full"}>
          <FormLabel alignSelf={"flex-start"} w={"20%"}>
            Appointment Times
          </FormLabel>
          <VStack w={"80%"}>
            {fields.map((_, index) => (
              <FormControl
                key={`preferred-appt-time-${index}`}
                w={"100%"}
                display={"flex"}
                alignItems={"center"}
              >
                <Input
                  w={"50%"}
                  {...register(`prefApptTimes.${index}.value`)}
                  placeholder='e.g. - Mondays after 2pm'
                />
                <IconButton
                  ml={1}
                  aria-label={"delete-pref-appt-time"}
                  onClick={() => remove(index)}
                  icon={<TbTrash />}
                />
              </FormControl>
            ))}
            <Button
              onClick={() => append({ value: "" })}
              colorScheme={"blue"}
              size='xs'
              variant='outline'
              alignSelf={"flex-start"}
              isDisabled={fields.length >= 3}
            >
              Add Preferred Time
            </Button>
          </VStack>
        </HStack>
      </Card>
      <HStack
        position={"absolute"}
        bottom={0}
        w={"full"}
        justifyContent={"space-between"}
      >
        <Button onClick={onPrev}>Previous</Button>
        <Button type={"submit"} colorScheme='blue'>
          Next
        </Button>
      </HStack>
    </form>
  );
};

const EnrollmentSummaryStep = ({
  currentUser,
  enrollmentData,
  enrollmentPatient,
  onPrev,
  onNext
}: {
  currentUser: WebUser;
  enrollmentData: IEnrollmentWizardForm;
  enrollmentPatient: ClientUser | ScreeningClient;
  onPrev: () => void;
  onNext: () => void;
}) => {
  const toast = useToast();
  const cancelRef = useRef(null);
  const { isOpen: confirmDialogOpen, onToggle: toggleConfirmDialog } =
    useDisclosure();
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();
  const { barber } = useBarber({
    uid: (enrollmentPatient as ClientUser).prefBarberId ?? "",
    initialFetch: true
  });
  const { barbershops } = useBarbershops();
  const { payers } = usePayers();
  const { primaryCareProvs } = usePrimaryCareProvs();
  const preferredBarbershop = barbershops?.find(
    (b) => b.id === (enrollmentPatient as ClientUser)?.prefBarbershopId
  );
  const enrollClient = async () => {
    if (enrollmentPatient instanceof ClientUser) {
      const clientUserService = new ClientUserService();
      enrollmentPatient.enrollmentDate = new Date();
      await clientUserService
        .updateClientUser(enrollmentPatient)
        .then(async () => {
          toast({
            status: "success",
            description: "Patient successfully enrolled"
          });
          if (
            placeBasedCareProvider &&
            placeBasedCareProvider.enrollmentIncentivePaymentAmount
          ) {
            await BillingTransactionService.createEnrollmentIncentive({
              clientId: enrollmentPatient.uid,
              placeBasedCareProvId: placeBasedCareProvider!.id,
              approver: currentUser
            }).catch((e) => {
              console.log("Failed to create enrollment incentive payment", e);
            });
          }
        });
    } else if (enrollmentPatient instanceof ScreeningClient) {
      // call conversion function with mapped in data
      toast({
        status: "error",
        description: "Not implemented for this patient type"
      });
    }
    onNext();
  };

  const enrollmentInformation = enrollmentData.enrollmentInformation;
  const patient = enrollmentPatient;
  const patientHypertensionField =
    patient instanceof ClientUser
      ? patient.isTreatedForHypertension
      : patient.hypertensionDiagnosis;

  return (
    <VStack w={"full"} h={"full"} mt={4}>
      <AlertDialog
        isOpen={confirmDialogOpen}
        onClose={toggleConfirmDialog}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogHeader>Complete Enrollment</AlertDialogHeader>
          <AlertDialogBody>
            You are about to complete patient enrollment. Once enrollment is
            completed the patient will be considered active and any further
            changes to their profile must be made on the Patient Profile screen.
            Are you sure you want to continue?
          </AlertDialogBody>
          <AlertDialogFooter display={"flex"} justifyContent={"space-between"}>
            <Button
              colorScheme='red'
              ref={cancelRef}
              onClick={toggleConfirmDialog}
            >
              No, take me back
            </Button>
            <Button colorScheme='blue' onClick={enrollClient}>
              Yes, complete enrollment
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Card variant={"outline"} w={"full"} p={4}>
        <Heading as={"h3"} fontSize={"large"}>
          Patient Information
        </Heading>
        <SimpleGrid columns={4} w={"full"} gap={4} mt={2}>
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text fontWeight='normal'>Patient Name</Text>
            <Text fontWeight='bold'>{patient?.name.display}</Text>
          </VStack>
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text fontWeight='normal'>Date of Birth</Text>
            <Text fontWeight='bold'>
              {patient?.dob
                ? DateTime.fromJSDate(patient.dob).toFormat("D")
                : ""}
            </Text>
          </VStack>
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text fontWeight='normal'>Sex</Text>
            <Text fontWeight='bold'>{(patient as ClientUser)?.sex}</Text>
          </VStack>
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text fontWeight='normal'>Race</Text>
            <Text fontWeight='bold'>{(patient as ClientUser)?.race}</Text>
          </VStack>
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text fontWeight='normal'>Phone Number</Text>
            <Text fontWeight='bold'>{patient?.phoneNumber}</Text>
          </VStack>
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text fontWeight='normal'>Email</Text>
            <Text fontWeight='bold'>{patient?.email}</Text>
          </VStack>

          <VStack alignItems={"flex-start"} spacing={0}>
            <Text fontWeight='normal'>Primary Care Home</Text>
            <Text fontWeight='bold'>
              {
                primaryCareProvs?.find(
                  (p: PrimaryCareProv) => p.id === patient?.primaryCareProvId
                )?.businessName
              }
            </Text>
          </VStack>
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text fontWeight='normal'>Insurance Provider</Text>
            <Text fontWeight='bold'>
              {payers?.find((p) => p.id === patient?.payerId)?.businessName}
            </Text>
          </VStack>
          {enrollmentData.bpVerification && (
            <VStack alignItems={"flex-start"} spacing={0}>
              <Text fontWeight='normal'>Verified Blood Pressure Reading</Text>
              <Text fontWeight='bold'>
                {enrollmentData.bpVerification.systolic +
                  "/" +
                  enrollmentData.bpVerification.diastolic}
              </Text>
              <Text fontWeight='bold'>
                Heart Rate: {enrollmentData.bpVerification.heartRate} BPM
              </Text>
            </VStack>
          )}
        </SimpleGrid>
      </Card>
      <Card p={4} w={"full"} variant='outline'>
        <Heading as={"h3"} fontSize={"large"}>
          Enrollment Information
        </Heading>
        <HStack w={"full"} justifyContent={"space-between"} mt={2}>
          <Text fontWeight='normal'>Hypertension Diagnosis</Text>
          <Text fontWeight='bold'>
            {patientHypertensionField
              ? "Being treated for hypertension"
              : "Is not being treated for hypertension"}
          </Text>
        </HStack>
        <HStack w={"full"} justifyContent={"space-between"}>
          <Text fontWeight={"normal"}>Insurance Number</Text>
          <Text fontWeight='bold'>
            {enrollmentInformation?.insuranceNumber}
          </Text>
        </HStack>
        <HStack w={"full"} justifyContent={"space-between"}>
          <Text fontWeight={"normal"}>Eligible for CHW Benefits</Text>
          <Text fontWeight='bold'>
            {(patient as ClientUser)?.sourceForCHWFunds}
          </Text>
        </HStack>
        <HStack w={"full"} justifyContent={"space-between"}>
          <Text fontWeight={"normal"}>Chart Prep Notes</Text>
          <Text fontWeight='bold'>{enrollmentInformation?.chartPrepNotes}</Text>
        </HStack>
      </Card>
      <Card p={4} w={"full"} variant={"outline"}>
        <Heading as='h3' fontSize={"large"}>
          Patient Preferences
        </Heading>
        <SimpleGrid columns={3} alignItems={"flex-start"} mt={2}>
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text fontWeight={"normal"}>Preferred Barber</Text>
            {barber && (
              <Text fontWeight={"bold"}>
                {barber.name.fullName ?? barber.name.display}
              </Text>
            )}
          </VStack>
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text fontWeight={"normal"}>Preferred Barbershop</Text>
            {preferredBarbershop && (
              <Text fontWeight={"bold"}>
                {preferredBarbershop?.businessName}
              </Text>
            )}
          </VStack>
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text fontWeight={"normal"}>Preferred Appointment Times</Text>
            <VStack alignItems={"flex-start"} spacing={0}>
              {(enrollmentPatient as ClientUser)?.prefApptTimes?.map((p, i) => (
                <Text
                  key={`summary-preferred-appt-time-${i}`}
                  fontWeight={"bold"}
                  // as={"i"}
                >
                  {p}
                </Text>
              ))}
            </VStack>
          </VStack>
        </SimpleGrid>
      </Card>
      <HStack
        w={"full"}
        spacing={4}
        position={"absolute"}
        bottom={0}
        justifyContent={"space-between"}
      >
        <Button onClick={onPrev}>Previous</Button>
        <div />
        <Button onClick={toggleConfirmDialog} colorScheme='blue'>
          Save
        </Button>
      </HStack>
    </VStack>
  );
};
