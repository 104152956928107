import { DocumentSnapshot } from "firebase/firestore";

import { OutreachService } from "../services/outreach-service";
import { Colors, Color } from "../constants/color";
import {
  ClientType,
  ClientTypeHelper,
  ContactMethod,
  ContactMethodHelper
} from "../constants/core-enums";
import { OutreachAttempt } from "./outreach-attempt";
import { UserName } from "./user-name";

export enum OutreachPurpose {
  Unknown = "Unknown",
  NewEnrollment = "NewEnrollment",
  ConfirmAppointment = "ConfirmAppointment"
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class OutreachPurposeHelper {
  static valueOf(searchString: string | null): OutreachPurpose | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(OutreachPurpose).includes(
      searchString as OutreachPurpose
    ) //
      ? (searchString as OutreachPurpose)
      : null;
  }
}

export enum OutreachStatus {
  Unknown = "Unknown",
  New = "New",
  InProgress = "InProgress",
  Completed = "Completed",
  Canceled = "Canceled"
}

interface IOutreachStatusData {
  color: Color;
  label: string;
}

export const outreachStatusData: Record<OutreachStatus, IOutreachStatusData> = {
  [OutreachStatus.Unknown]: {
    color: Colors.red,
    label: "label_unknown"
  },
  [OutreachStatus.New]: {
    color: Colors.yellow,
    label: "label_new"
  },
  [OutreachStatus.InProgress]: {
    color: Colors.white,
    label: "label_in_progress"
  },
  [OutreachStatus.Completed]: {
    color: Colors.green,
    label: "label_completed"
  },
  [OutreachStatus.Canceled]: {
    color: Colors.black,
    label: "label_canceled"
  }
};

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class OutreachStatusHelper {
  static valueOf(searchString: string | null): OutreachStatus | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(OutreachStatus).includes(
      searchString as OutreachStatus
    ) //
      ? (searchString as OutreachStatus)
      : null;
  }
}

/// Outreach records are maintained in a subcollection on ClientUser.
///* Due to a Firestore limitation, when working with subcollections, for queries to work we must also store
///* the Firestore document id within the document itself (stored as "modelId").
export interface IOutreachData {
  id: string;
  placeBasedCareProvId: string;
  clientId: string;
  clientType: ClientType;
  clientName: UserName;
  clientEmail: string;
  clientPhone: string;
  prefContactMethod: ContactMethod;
  staffId: string;
  staffName: UserName;
  createdDate: Date;
  purpose: OutreachPurpose;
  status: OutreachStatus;
  attempts: OutreachAttempt[];
}

export class Outreach {
  id: string;
  placeBasedCareProvId: string;
  clientId: string;
  clientType: ClientType;
  clientName: UserName;
  clientEmail: string;
  clientPhone: string;
  prefContactMethod: ContactMethod;
  staffId: string;
  staffName: UserName;
  createdDate: Date;
  purpose: OutreachPurpose;
  status: OutreachStatus;
  attempts: OutreachAttempt[];

  constructor({
    id,
    placeBasedCareProvId,
    clientId,
    clientType,
    clientName,
    clientEmail,
    clientPhone,
    prefContactMethod,
    staffId,
    staffName,
    createdDate,
    purpose,
    status,
    attempts
  }: IOutreachData) {
    this.id = id;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.clientId = clientId;
    this.clientType = clientType;
    this.clientName = clientName;
    this.clientEmail = clientEmail;
    this.clientPhone = clientPhone;
    this.prefContactMethod = prefContactMethod;
    this.staffId = staffId;
    this.staffName = staffName;
    this.createdDate = createdDate;
    this.purpose = purpose;
    this.status = status;
    this.attempts = attempts;
  }

  async updateDb(): Promise<void> {
    const db = new OutreachService();
    await db.updateOutreach(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): Outreach {
    const data = docSnap.data() as { [key: string]: any };
    return Outreach.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): Outreach {
    return new Outreach({
      id: id,
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      clientId: data["clientId"] ?? "",
      clientType:
        ClientTypeHelper.valueOf(data["clientType"] ?? "") ??
        ClientType.ClientUser,
      clientName: UserName.fromMap(data["clientName"] ?? {}),
      clientEmail: data["clientEmail"] ?? "",
      clientPhone: data["clientPhone"] ?? "",
      prefContactMethod:
        ContactMethodHelper.valueOf(data["prefContactMethod"] ?? "") ??
        ContactMethod.Email,
      staffId: data["staffId"] ?? "",
      staffName: UserName.fromMap(data["staffName"] ?? {}),
      createdDate: data["createdDate"]
        ? new Date(data["createdDate"])
        : new Date(1, 0, 1), // default to 1/1/0001
      purpose:
        OutreachPurposeHelper.valueOf(data["purpose"] ?? "") ??
        OutreachPurpose.Unknown,
      status:
        OutreachStatusHelper.valueOf(data["status"] ?? "") ??
        OutreachStatus.Unknown,
      attempts: (data["attempts"] ?? []).map(
        (element: { [key: string]: any }) => OutreachAttempt.fromMap(element)
      )
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      placeBasedCareProvId: this.placeBasedCareProvId,
      clientId: this.clientId,
      clientType: this.clientType,
      clientName: this.clientName.toJson(),
      clientEmail: this.clientEmail,
      clientPhone: this.clientPhone,
      prefContactMethod: this.prefContactMethod,
      staffId: this.staffId,
      staffName: this.staffName.toJson(),
      createdDate: this.createdDate.toISOString(),
      purpose: this.purpose,
      status: this.status,
      attempts: this.attempts.map((element) => element.toJson())
    };
  }
}
