import useClientUserSubscription from "@/hooks/useClientUserSubscription";
import useTaskBase from "@/hooks/useTaskBase";
import useTasks from "@/hooks/useTasks";
import {
  Text,
  Accordion,
  AccordionItem,
  Select,
  AccordionButton,
  AccordionPanel,
  IconButton,
  Table,
  Td,
  Thead,
  Tr,
  Tbody,
  Input,
  Center,
  AccordionIcon,
  HStack,
  Button,
  useToast
} from "@chakra-ui/react";
import { FreqPeriod, UserType } from "@oben-core-web/constants/core-enums";
import {
  ClientTask,
  ClientTaskCategory,
  ClientTaskType
} from "@oben-core-web/models/client-task";
import { useFieldArray, useForm } from "react-hook-form";
import _, { isEqual } from "lodash";
import { DateTime } from "luxon";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  TaskStatus,
  TaskStatusChange
} from "@oben-core-web/models/task-status-change";
import { CarePlanTransactionService } from "@oben-core-web/services/care-plan-transaction-service";
import { useEffect, useRef } from "react";
import { WebUser } from "@oben-core-web/models/web-user";

interface ICPWizardStep4 {
  carePlanId: string;
  clientId: string;
  currentUser: WebUser;
  pharmacistId: string;
  onSubmit?: () => void;
  onCancel?: () => void;
}

interface ITaskForm {
  taskBaseId: string;
  modelId: string;
  name: string;
  date: string;
  type: ClientTaskType;
  taskCategory: ClientTaskCategory;
  description: string;
  frequencyCount: number;
  frequencyPeriod: FreqPeriod;
  duration: number;
  fieldArrayIndex: number;
}

interface ICPWizardStep4Form {
  healthMaintenanceTasks: ITaskForm[];
}

const CPWizardStep4 = ({
  carePlanId,
  clientId,
  pharmacistId,
  currentUser,
  onSubmit,
  onCancel
}: ICPWizardStep4) => {
  const toast = useToast();
  const { clientUser } = useClientUserSubscription(clientId);
  const { taskBases } = useTaskBase({
    placeBasedCareProvId: currentUser?.placeBasedCareProvId ?? ""
  });
  const clientTasks = useTasks({ clientId, carePlanId });
  const clientTasksRef = useRef(clientTasks);
  const { control, register, handleSubmit, setValue, formState, reset } =
    useForm<ICPWizardStep4Form>({
      defaultValues: {
        healthMaintenanceTasks: []
      }
    });
  const {
    fields: healthMaintenaceFields,
    append: appendTask,
    remove: removeTask
  } = useFieldArray({
    control,
    name: "healthMaintenanceTasks"
  });
  const healthMaintenanceTaskBases = taskBases.filter(
    (tb) => tb.taskType === ClientTaskType.HealthcareAction
  );
  const healthMaintenanceTaskBasesByCategory = _.groupBy(
    healthMaintenanceTaskBases,
    (tb) =>
      tb.taskCategory === ClientTaskCategory.Unknown
        ? "General"
        : tb.taskCategory
  );
  formState.isDirty;
  useEffect(() => {
    if (!isEqual(clientTasks, clientTasksRef.current)) {
      clientTasksRef.current = clientTasks;
      reset({
        healthMaintenanceTasks: clientTasks
          .filter((ct) => ct.taskType === ClientTaskType.HealthcareAction)
          .map((t: ClientTask, i) => ({
            modelId: t.modelId,
            taskBaseId: t.clientTaskBaseId,
            name: t.name,
            taskCategory: t.taskCategory,
            type: t.taskType,
            date: t.dueDate
              ? DateTime.fromJSDate(t.dueDate).toFormat("yyyy-MM-dd")
              : "",
            description: t.description,
            frequencyCount: t.frequencyCount,
            frequencyPeriod: t.frequencyPeriod as FreqPeriod,
            fieldArrayIndex: i
          }))
      });
    }
  }, [reset, clientTasks]);

  const submissionHandler = async (data: ICPWizardStep4Form) => {
    if (!currentUser) return;
    const { healthMaintenanceTasks } = data;
    // only work with tasks that were dirtied
    const dirtyFields = formState.dirtyFields.healthMaintenanceTasks;
    const dirtyRows = healthMaintenanceTasks.filter(
      (hmt) => dirtyFields?.[hmt.fieldArrayIndex]
    );
    // map into ClientTask model
    const taskChanges = dirtyRows.map(
      (hmt) =>
        new ClientTask({
          modelId: hmt.modelId,
          clientId,
          carePlanId,
          clientTaskBaseId: hmt.taskBaseId,
          placeBasedCareProvId: currentUser?.placeBasedCareProvId ?? "",
          taskType: ClientTaskType.HealthcareAction,
          taskCategory: hmt.taskCategory,
          name: hmt.name,
          description: hmt.description,
          frequencyCount: 1,
          frequencyPeriod: "Once" as FreqPeriod,
          dueDate: DateTime.fromJSDate(new Date(hmt.date)).toUTC().toJSDate(),
          createdDate: new Date(),
          creatorId: pharmacistId,
          creatorType: UserType.Pharmacist,
          expectedCount: 1,
          statusChanges: [
            new TaskStatusChange({
              date: new Date(),
              status: hmt.modelId ? TaskStatus.InProgress : TaskStatus.New
            })
          ],
          taskCompletionDates: []
        })
    );
    // submit dirtied rows to transaction
    await CarePlanTransactionService.saveTasks({
      clientId,
      carePlanId,
      tasks: taskChanges
    })
      .then(() => {
        onSubmit && onSubmit();
      })
      .catch((e) => {
        console.log({ CarePlanStep4Error: e });
        toast({
          status: "error",
          description: "Error saving health maintenance tasks"
        });
      });
  };
  return (
    <>
      {clientUser && (
        <form
          style={{
            minHeight: "30rem",
            display: "flex",
            position: "relative",
            flexDirection: "column"
          }}
          onSubmit={handleSubmit(submissionHandler)}
        >
          <Accordion allowMultiple>
            {Object.entries(healthMaintenanceTaskBasesByCategory).map(
              ([category, bases], key) => {
                return (
                  <AccordionItem key={`hm-task-accordion-${category}-${key}`}>
                    <Text as='h2' fontSize='large'>
                      <AccordionButton
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <Text fontSize={"large"} fontWeight={"bold"}>
                          {category}
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>
                    </Text>
                    <AccordionPanel>
                      {healthMaintenaceFields.filter((hmf) =>
                        hmf.taskCategory === "Unknown"
                          ? "General" === category
                          : hmf.taskCategory === category
                      ).length > 0 && (
                        <Table size='xs'>
                          <Thead>
                            <Tr px={0} fontSize={"small"}>
                              <Td w={"25%"}>Name</Td>
                              <Td w={"50%"}>Description</Td>
                              <Td w={"20%"}>Due Date</Td>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {healthMaintenaceFields
                              .filter((hmf) =>
                                hmf.taskCategory === "Unknown"
                                  ? "General" === category
                                  : hmf.taskCategory === category
                              )
                              .map((field) => {
                                return (
                                  <Tr
                                    key={`hm-task-item-${field.fieldArrayIndex}`}
                                  >
                                    <Td w={"25%"}>
                                      <Select
                                        placeholder={"Select a task"}
                                        {...register(
                                          `healthMaintenanceTasks.${field.fieldArrayIndex}.taskBaseId`
                                        )}
                                        onChange={(e) => {
                                          if (e.target.value) {
                                            const specificTask = taskBases.find(
                                              (tb) => tb.id === e.target.value
                                            );
                                            setValue(
                                              `healthMaintenanceTasks.${field.fieldArrayIndex}`,
                                              {
                                                ...field,
                                                taskBaseId: specificTask!.id,
                                                date: DateTime.fromJSDate(
                                                  new Date()
                                                )
                                                  .plus({
                                                    days: specificTask!
                                                      .defaultDuration
                                                  })
                                                  .toFormat("yyyy-MM-dd"),
                                                type: specificTask!.taskType,
                                                frequencyCount:
                                                  specificTask!
                                                    .defaultFreqCount,
                                                frequencyPeriod: specificTask!
                                                  .defaultFreqPeriod as FreqPeriod,
                                                duration:
                                                  specificTask!.defaultDuration,
                                                name: specificTask!.name,
                                                description:
                                                  specificTask!.description,
                                                fieldArrayIndex:
                                                  field.fieldArrayIndex
                                              }
                                            );
                                          }
                                        }}
                                      >
                                        {bases.map((tb) => (
                                          <option
                                            key={`task-id-${tb.id}`}
                                            value={tb.id}
                                          >
                                            {tb.name}
                                          </option>
                                        ))}
                                      </Select>
                                    </Td>
                                    <Td w={"50%"}>
                                      <Input
                                        {...register(
                                          `healthMaintenanceTasks.${field.fieldArrayIndex}.description`
                                        )}
                                      />
                                    </Td>
                                    <Td w={"20%"}>
                                      <Input
                                        type='date'
                                        {...register(
                                          `healthMaintenanceTasks.${field.fieldArrayIndex}.date`
                                        )}
                                      />
                                    </Td>
                                    <Td>
                                      <IconButton
                                        aria-label={`add-${category}-task`}
                                        icon={<DeleteIcon />}
                                        size='sm'
                                        onClick={() =>
                                          removeTask(field.fieldArrayIndex)
                                        }
                                      />
                                    </Td>
                                  </Tr>
                                );
                              })}
                          </Tbody>
                        </Table>
                      )}
                      <Center>
                        <IconButton
                          aria-label={`add-${category}-task`}
                          icon={<AddIcon />}
                          size='sm'
                          onClick={() =>
                            appendTask({
                              taskBaseId: "",
                              modelId: "",
                              date: DateTime.fromJSDate(new Date()).toFormat(
                                "yyyy-MM-dd"
                              ),
                              placeBasedCareProvId:
                                currentUser.placeBasedCareProvId ?? "",
                              type: category,
                              taskCategory: category,
                              name: "",
                              description: "",
                              frequencyCount: 0,
                              frequencyPeriod: FreqPeriod.Once,
                              duration: 1,
                              fieldArrayIndex: healthMaintenaceFields.length
                            } as ITaskForm)
                          }
                        />
                      </Center>
                    </AccordionPanel>
                  </AccordionItem>
                );
              }
            )}
          </Accordion>
          <HStack
            mt={"2rem"}
            w={"full"}
            justifyContent={"space-between"}
            pos={"absolute"}
            bottom={0}
          >
            {onCancel && <Button onClick={onCancel}>Previous</Button>}
            {onSubmit && <Button type='submit'>Next</Button>}
          </HStack>
        </form>
      )}
    </>
  );
};

export default CPWizardStep4;
