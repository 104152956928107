import useBillableItems from "@/hooks/useBillableItems";
import useCurrentUser from "@/hooks/useCurrentUser";
import {
  Center,
  HStack,
  Text,
  VStack,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Spinner,
  useDisclosure,
  Tooltip,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast
} from "@chakra-ui/react";
import { UserType } from "@oben-core-web/constants/core-enums";
import { DateTime } from "luxon";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { useMemo, useRef, useState } from "react";
import { BillableItemService } from "@oben-core-web/services/billable-item-service";
import { BillableItem } from "@oben-core-web/models/billable-item";
import {
  BillStatus,
  BillStatusChange
} from "@oben-core-web/models/bill-status-change";
import { BillingTransactionService } from "@oben-core-web/services/billing-transaction-service";
import { StripeStatus } from "@oben-core-web/models/barber-user";
import useBarbers from "@/hooks/useBarbers";
import { filter, groupBy, startCase, uniq } from "lodash";

// TODO: be able to filter by city

const PaymentsTable = () => {
  const { currentUser } = useCurrentUser();
  const { billableItems, refetch } = useBillableItems(
    currentUser?.placeBasedCareProvId ?? ""
  );
  const [activeFilter, setActiveFilter] =
    useState<keyof typeof billableItemFilters>("requiresResponse");
  const { barbers } = useBarbers(currentUser?.placeBasedCareProvId ?? "");
  const billableItemsByEvent = groupBy(billableItems, "billableEventId");
  const columnHeaders: ColDef[] = useMemo(
    () => [
      {
        headerName: "Date",
        field: "date",
        valueFormatter: (v) =>
          DateTime.fromJSDate(v.data[0].serviceDate).toFormat("MM/dd/yy"),
        flex: 0.15,
        cellStyle: { display: "flex", alignItems: "center" },
        sortable: true,
        headerTooltip: "Date of service",
        comparator: (a, b, nA, nB) => {
          const biA = nA.data[0].serviceDate;
          const biB = nB.data[0].serviceDate;
          return biA - biB ? 1 : -1;
        }
      },
      {
        field: "description",
        flex: 0.5,
        headerTooltip: "Description of services rendered",
        cellRenderer: (params: any) => {
          const billableItems = params.data;
          return (
            <VStack
              w={"full"}
              h={"full"}
              spacing={2}
              alignItems={"flex-start"}
              justifyContent={"space-evenly"}
            >
              {billableItems.map((bi: BillableItem) => {
                return (
                  <HStack key={bi.modelId} spacing={0}>
                    <Text
                      lineHeight={"1em"}
                      fontSize={"sm"}
                      whiteSpace={"wrap"}
                    >
                      {bi.description}
                    </Text>
                  </HStack>
                );
              })}
            </VStack>
          );
        },
        sortable: false
      },
      {
        field: "amount",
        cellRenderer: (params: any) => {
          const billableItems = params.data;
          return (
            <VStack
              w={"full"}
              h={"full"}
              spacing={2}
              alignItems={"flex-start"}
              justifyContent={"space-evenly"}
            >
              {billableItems.map((bi: BillableItem) => {
                return (
                  <HStack key={bi.modelId} spacing={0}>
                    <Text
                      lineHeight={"1em"}
                      fontSize={"sm"}
                      whiteSpace={"wrap"}
                    >
                      ${(bi.amount / 100).toFixed(2)}
                    </Text>
                  </HStack>
                );
              })}
            </VStack>
          );
        },
        headerTooltip: "Amount per line item",
        // valueFormatter: (v) => "$" + (v.data.amount / 100).toFixed(2),
        flex: 0.125,
        sortable: false
      },
      {
        field: "total",
        cellStyle: { display: "flex", alignItems: "center" },
        valueGetter: (params: any) => {
          const billableItems = params.data;
          return (
            billableItems.reduce(
              (a: number, c: BillableItem) => (a += c.amount),
              0
            ) / 100
          ).toFixed(2);
        },
        valueFormatter: (params: any) => {
          return "$" + `${params.value}`;
        },
        flex: 0.125,
        sortable: true,
        comparator: (a, b, nA, nB) => {
          const amountA = nA.data.reduce(
            (a: number, c: BillableItem) => (a += c.amount),
            0
          );
          const amountB = nB.data.reduce(
            (a: number, c: BillableItem) => (a += c.amount),
            0
          );
          return amountA - amountB;
        },
        headerTooltip: "Total amount to be approved"
      },
      {
        field: "clientName",
        headerName: "Patient",
        valueGetter: (v) => {
          const bis = v.data;
          return bis[0].clientName.fullName;
        },
        cellStyle: { display: "flex", alignItems: "center" },
        sortable: true,
        headerTooltip: "Recipient of the service"
      },
      {
        field: "barber",
        valueGetter: (v) => {
          const bis = v.data;
          return (
            barbers?.find((b) => b.uid === bis[0].barberId)?.name.fullName ?? ""
          );
        },
        cellStyle: { display: "flex", alignItems: "center" },
        sortable: true,
        headerTooltip: "Recipient of service payment"
      },
      {
        field: "status",
        valueGetter: (v) => {
          const lastStatus =
            v.data[0].billStatusChanges[v.data[0].billStatusChanges.length - 1]
              ?.status;
          return lastStatus
            ? lastStatus === BillStatus.New
              ? "Pending Approval"
              : lastStatus === BillStatus.ApprovedForSubmission
              ? "Approved"
              : startCase(lastStatus)
            : "No status";
        },
        headerTooltip: "Status of the payment request",
        flex: 0.2,
        tooltipValueGetter: ({ value }) => {
          // switch-case uses strings because values are transformed in valueGetter
          switch (value) {
            case "Pending Approval":
              return "This payment request is pending approval by the Client or Care team";
            case "Approved":
              return "This payment request has been approved and is awaiting submission";
            case "Submitted":
              return "This payment request has been approved and submitted for payment";
            case "Paid":
              return "This payment request has been paid";
            case "Rejected By PBCP":
              return "This payment request has been rejected by a member of the Care Team";
            case "Rejected By Patient":
              return "This payment request has been rejected by the Patient";
            default:
              return value;
          }
        },
        cellStyle: { display: "flex", alignItems: "center" },
        sortable: true
      },
      {
        headerName: "Actions",
        field: "actions",
        cellRenderer: (params: any) => (
          <BillableItemActions
            {...params}
            billableItems={params.data}
            onActionComplete={refetch}
            transfersEnabled={
              barbers?.find((b) => b.uid === params.data[0].barberId)
                ?.stripeStatus === StripeStatus.TransfersEnabled
            }
          />
        ),
        sortable: false,
        filter: false,
        flex: 0.2
      }
    ],
    [refetch, barbers]
  );
  const parsedBillableEventData = useMemo(() => {
    // only show billableItems that are New and unsubmitted or not denied
    return filter(
      Object.values(billableItemsByEvent),
      billableItemFilters[activeFilter ?? "all"]
    );
  }, [billableItemsByEvent, activeFilter]);

  // const parsedBillableItemData = useMemo(() => {
  //   // only show billableItems that are New and unsubmitted or not denied
  //   return billableItems.filter(
  //     // (bi) =>
  //     //   bi.stripePmtStatusChanges.length === 0 &&
  //     //   !bi.billStatusChanges.some((bsc) => bsc.status === BillStatus.Denied)
  //     billableItemFilters[activeFilter ?? "all"]
  //   );
  // }, [billableItems, activeFilter]);
  if (currentUser?.userType !== UserType.ProgramManager) {
    return (
      <Center h={"full"}>
        <Text>Only Program Managers may view this screen</Text>
      </Center>
    );
  }
  return (
    <VStack p={2} h={"full"} w={"full"}>
      <HStack
        w={"full"}
        alignItems={"center"}
        // alignItems={"flex-start"}
        justifyContent={"center"}
        spacing={2}
        border={"1px solid lightgrey"}
        p={2}
        rounded={"md"}
      >
        <FormLabel m={0}>Active Filter</FormLabel>
        <Select
          bg={"white"}
          flex={1}
          value={activeFilter}
          onChange={(e) =>
            setActiveFilter(e.target.value as keyof typeof billableItemFilters)
          }
        >
          <option value={"all"}>All</option>
          <option value={"requiresResponse"}>Pending</option>
          <option value={"submitted"}>Submitted</option>
          <option value={"denied"}>Denied</option>
          <option value={"paid"}>Paid</option>
        </Select>
      </HStack>
      <div
        className='ag-theme-quartz'
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          rowData={Object.values(parsedBillableEventData)}
          // rowData={Object.entries(billableItemsByEvent)}
          // rowData={parsedBillableItemData}
          rowHeight={70}
          columnDefs={columnHeaders as any}
        />
      </div>
    </VStack>
  );
};

export default PaymentsTable;

const BillableItemActions = ({
  onActionComplete,
  transfersEnabled,
  billableItems
}: {
  onActionComplete: () => void;
  transfersEnabled: boolean;
  billableItems: BillableItem[];
}) => {
  const billableEventStatus = useMemo(() => {
    const statuses = billableItems.map((bi) => getLastBillStatus(bi));
    const uniqueStatuses = uniq(statuses);
    if (uniqueStatuses.length > 1) {
      return BillStatus.Unknown;
    } else {
      return uniqueStatuses[0] as BillStatus;
    }
  }, [billableItems]);
  const { currentUser } = useCurrentUser();
  const [action, setAction] = useState<"Approved" | "Denied">();
  const [loading, setLoading] = useState(false);
  const [denialReason, setDenialReason] = useState("");
  const cancelRef = useRef(null);
  const { onClose, isOpen, onOpen } = useDisclosure();
  const toast = useToast();
  // const { barber } = useBarber(data.barberId);
  // const barberCanAcceptPayments =
  //   barber?.stripeStatus === StripeStatus.TransfersEnabled;

  const updateBillableItemApproval = async (status: "Approved" | "Denied") => {
    if (!currentUser || !transfersEnabled) return;
    setLoading(true);
    try {
      const billableItemService = new BillableItemService();
      if (status === "Denied") {
        for (const bi of billableItems) {
          const currentBillableitem = new BillableItem(bi);
          currentBillableitem.billStatusChanges.push(
            new BillStatusChange({
              status: BillStatus.RejectedByPBCP,
              date: new Date(),
              editorId: currentUser.uid,
              editorType: currentUser.userType,
              details: `Denied by ${currentUser?.name.fullName}${
                denialReason ? " : " + denialReason : ""
              }`
            })
          );
          currentBillableitem.currentStatus = BillStatus.RejectedByPBCP;
          await billableItemService.updateBillableItem(currentBillableitem);
        }
      } else {
        const billableEventId = billableItems[0].billableEventId;
        const receiverId = billableItems[0].barberId;
        if (!receiverId) {
          toast({
            status: "error",
            description: "Failed to send payment: Missing Barber ID"
          });
          return;
        }
        await BillingTransactionService.approveBillableItem({
          billableEventId,
          editorId: currentUser.uid,
          receiverId,
          billStatusUpdate: BillStatus.Submitted
        })
          .then(() => {
            toast({ status: "success", description: "Payment submitted" });
          })
          .catch((e) => {
            console.log("Failed to approve billable item", e);
            if (typeof e === "string") {
              toast({ status: "error", description: e });
              throw e;
            }
          });
      }
    } catch (e) {
      console.log("Failed to update billableItem:", e);
    } finally {
      setLoading(false);
      onClose();
      onActionComplete();
    }
  };
  const paymentAmount = billableItems.reduce((a, c) => (a += c.amount), 0);
  const pbcpRejectionReason =
    billableEventStatus === BillStatus.RejectedByPBCP
      ? billableItems[0]?.billStatusChanges[
          billableItems[0].billStatusChanges.length - 1
        ]?.details
      : "";
  const dialogHeader = useMemo(
    () =>
      action === "Approved"
        ? "Approve Payment Request"
        : action === "Denied"
        ? "Deny Payment Request"
        : "",
    [action]
  );
  const dialogBody = useMemo(
    () =>
      action === "Approved"
        ? pbcpRejectionReason
          ? `This item was previously rejected by a member of your care team.  Are you sure you want to proceed with this payment of ${(
              paymentAmount / 100
            ).toFixed(2)}?`
          : `Approve payment of $${(paymentAmount / 100).toFixed(2)}.`
        : action === "Denied"
        ? `Deny payment of $${(paymentAmount / 100).toFixed(2)}.`
        : "",
    [action, paymentAmount, pbcpRejectionReason]
  );
  const actionsEnabled =
    billableEventStatus === BillStatus.ApprovedForSubmission;

  const stripeSubmissionOrPaidDate =
    billableItems[0].currentStatus === BillStatus.Submitted ||
    billableItems[0].currentStatus === BillStatus.Paid
      ? DateTime.fromJSDate(
          billableItems[0].billStatusChanges[
            billableItems[0].billStatusChanges.length - 1
          ].date
        ).toFormat("M/dd/yy")
      : null;

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {dialogHeader}
            </AlertDialogHeader>

            <AlertDialogBody display={"flex"} flexDir={"column"}>
              <Text fontWeight={"normal"}>
                {dialogBody} You can't undo this action afterwards.
              </Text>
              {action === "Denied" ? (
                <FormControl>
                  <FormLabel>Denial Reason (optional)</FormLabel>
                  <Input
                    placeholder={"Why is this request being denied?"}
                    value={denialReason}
                    onChange={(e) => setDenialReason(e.target.value)}
                  />
                </FormControl>
              ) : (
                <></>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme='blue'
                onClick={() => action && updateBillableItemApproval(action)}
                ml={3}
              >
                {!loading ? "Confirm" : <Spinner />}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {billableEventStatus !== BillStatus.Submitted &&
      billableEventStatus !== BillStatus.Paid ? (
        <HStack alignItems={"center"} h={"full"}>
          <Tooltip
            label={
              pbcpRejectionReason
                ? pbcpRejectionReason
                : transfersEnabled
                ? "Approve Payment Request"
                : "Barber cannot accept payments"
            }
          >
            <Button
              onClick={() => {
                setAction("Approved");
                onOpen();
              }}
              size='sm'
              colorScheme='blue'
              isDisabled={
                !transfersEnabled || (!actionsEnabled && !pbcpRejectionReason)
              }
            >
              Submit
            </Button>
          </Tooltip>
          <Tooltip
            label={
              pbcpRejectionReason ? pbcpRejectionReason : "Deny Payment Request"
            }
          >
            <Button
              onClick={() => {
                setAction("Denied");
                onOpen();
              }}
              size='sm'
              colorScheme='red'
              isDisabled={!transfersEnabled || !actionsEnabled}
            >
              Reject
            </Button>
          </Tooltip>
        </HStack>
      ) : (
        <HStack h={"full"} color={"gray.500"} fontWeight={"normal"}>
          {billableEventStatus === BillStatus.Submitted ? (
            <Text as={"i"} textAlign={"center"} fontSize={"sm"}>
              In Transit
              {stripeSubmissionOrPaidDate
                ? `: ${stripeSubmissionOrPaidDate}`
                : ""}
            </Text>
          ) : (
            <Text as={"i"} fontSize={"sm"}>
              Completed
              {stripeSubmissionOrPaidDate
                ? `: ${stripeSubmissionOrPaidDate}`
                : ""}
            </Text>
          )}
        </HStack>
      )}
    </>
  );
};

const billableItemFilters = {
  all: (bis: BillableItem[]) => {
    return bis.every((bi) => !!bi.modelId);
  },
  requiresResponse: (bis: BillableItem[]) => {
    return bis.every(
      (bi) =>
        bi.stripePmtStatusChanges.length === 0 &&
        getLastBillStatus(bi) === BillStatus.ApprovedForSubmission
    );
  },
  submitted: (bis: BillableItem[]) => {
    return bis.every(
      (bi) =>
        bi.stripePmtStatusChanges?.length > 0 &&
        getLastBillStatus(bi) === BillStatus.Submitted
    );
  },
  denied: (bis: BillableItem[]) => {
    return bis.every((bi) => {
      const currentStatus = getLastBillStatus(bi);
      return (
        currentStatus === BillStatus.RejectedByPBCP ||
        currentStatus === BillStatus.RejectedByPatient
      );
    });
  },
  paid: (bis: BillableItem[]) => {
    return bis.every((bi) => getLastBillStatus(bi) === BillStatus.Paid);
  }
};

const getLastBillStatus = (bi: BillableItem) => {
  if (bi.currentStatus) return bi.currentStatus;
  if (bi.billStatusChanges.length === 0) return null;
  return bi.billStatusChanges[bi.billStatusChanges.length - 1]?.status ?? null;
};
