import { UserName } from "./user-name";

export enum OutreachAttemptResult {
  Unknown = "Unknown",
  WrongContactInfo = "WrongContactInfo",
  NoAnswer = "NoAnswer",
  LeftMessage = "LeftMessage",
  NotAvailable = "NotAvailable",
  SpokeToClient = "SpokeToClient",
  SentTextMessage = "SentTextMessage"
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class OutreachAttemptResultHelper {
  static valueOf(searchString: string | null): OutreachAttemptResult | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(OutreachAttemptResult).includes(
      searchString as OutreachAttemptResult
    ) //
      ? (searchString as OutreachAttemptResult)
      : null;
  }
}

export interface IOutreachAttemptData {
  date: Date;
  staffId: string;
  staffName: UserName;
  note: string | null;
  result: OutreachAttemptResult;
}

// OutreachAttempt records are maintained in a list on Outreach
export class OutreachAttempt {
  date: Date;
  staffId: string;
  staffName: UserName;
  note: string | null;
  result: OutreachAttemptResult;

  constructor({
    date,
    staffId,
    staffName,
    note,
    result
  }: IOutreachAttemptData) {
    this.date = date;
    this.staffId = staffId;
    this.staffName = staffName;
    this.note = note;
    this.result = result;
  }

  static fromMap(data: { [key: string]: any }): OutreachAttempt {
    return new OutreachAttempt({
      date: data["date"] ? new Date(data["date"]) : new Date(1, 0, 1), // default to 1/1/0001
      staffId: data["staffId"] ?? "",
      staffName: UserName.fromMap(data["staffName"] ?? {}),
      note: data["note"] ?? null,
      result:
        OutreachAttemptResultHelper.valueOf(data["result"] ?? "") ??
        OutreachAttemptResult.Unknown
    });
  }

  toJson(): { [key: string]: any } {
    return {
      date: this.date.toISOString(),
      staffId: this.staffId,
      staffName: this.staffName.toJson(),
      note: this.note,
      result: this.result
    };
  }
}
