import { DocumentSnapshot } from "firebase/firestore";

// import { BarberAvatar, BarberAvatarExtension } from 'oben_images/oben_images'; // TODO: Uncomment this line after creating the BarberAvatar class
import { BarberUserService } from "../services/barber-user-service";
import { AppInfo } from "./app-info";
import { StripeRemediationItem } from "./stripe-remediation-item";
import { PlatformInfo } from "./platform-info";
import { UserName } from "./user-name";

export enum StripeStatus {
  Unknown = "Unknown",
  Started = "Started",
  RemediationNeeded = "RemediationNeeded",
  TransfersEnabled = "TransfersEnabled",
  Disabled = "Disabled"
}

// TypeScript does not support extensions on enums, so we use a static method approach
// on a helper class to mimic the Dart behavior.
export class StripeStatusHelper {
  static valueOf(searchString: string | null): StripeStatus | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(StripeStatus).includes(searchString as StripeStatus) //
      ? (searchString as StripeStatus)
      : null;
  }
}

export enum LegalEntityType {
  Individual = "Individual",
  Company = "Company",
  Nonprofit = "Nonprofit",
}

interface ILegalEntityTypeData {
  localizedLabel: string;
  stripeValue: string;
}

export const legalEntityTypeData: Record<LegalEntityType, ILegalEntityTypeData> = {
  [LegalEntityType.Individual]: {
    localizedLabel: 'b_label_individual', // used in selectors
    stripeValue: 'individual', // used by Stripe
  },
  [LegalEntityType.Company]: {
    localizedLabel: 'b_label_company', // used in selectors
    stripeValue: 'company', // used by Stripe
  },
  [LegalEntityType.Nonprofit]: {
    localizedLabel: 'b_label_nonprofit', // used in selectors
    stripeValue: 'non_profit', // used by Stripe
  },
}

export class LegalEntityTypeHelper {
  static valueOf(searchString: string | null): LegalEntityType | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(LegalEntityType).includes(searchString as LegalEntityType) //
      ? (searchString as LegalEntityType)
      : null;
  }
}

export interface IBarberUserData {
  // common fields for all "users"
  uid: string;
  name: UserName;
  email: string;
  phoneNumber: string | null;
  enabled: boolean;
  pwdNeedsReset: boolean;
  // mobile-specific fields
  appInfo: AppInfo;
  platformInfo: PlatformInfo;
  // barber-specific fields
  // avatar: BarberAvatar; // TODO: Uncomment this line after creating the BarberAvatar class
  currentshopId: string; // shop where barber is currently working (or last worked)
  barbershopIds: string[];
  placeBasedCareProvIds: string[];
  legalEntityType: LegalEntityType | null;
  stripeStatus: StripeStatus;
  stripeRemediationItems: StripeRemediationItem[];
  serviceFee: number | null; // in cents (eg. 3500 = $35.00)
  noShowFee: number | null; // in cents
  cancelFee: number | null; // in cents
  cancelWindow: number | null; // in hours
}

export class BarberUser {
  uid: string;
  name: UserName;
  email: string;
  phoneNumber: string | null;
  enabled: boolean;
  pwdNeedsReset: boolean;
  // mobile-specific fields
  appInfo: AppInfo;
  platformInfo: PlatformInfo;
  // barber-specific fields
  // avatar: BarberAvatar; // TODO: Uncomment this line after creating the BarberAvatar class
  currentshopId: string; // shop where barber is currently working (or last worked)
  barbershopIds: string[];
  placeBasedCareProvIds: string[];
  legalEntityType: LegalEntityType | null;
  stripeStatus: StripeStatus;
  stripeRemediationItems: StripeRemediationItem[];
  serviceFee: number | null;
  noShowFee: number | null;
  cancelFee: number | null;
  cancelWindow: number | null;

  constructor({
    uid,
    name,
    email,
    phoneNumber,
    enabled,
    pwdNeedsReset,
    appInfo,
    platformInfo,
    // avatar, // TODO: Uncomment this line after creating the BarberAvatar class
    currentshopId,
    barbershopIds,
    placeBasedCareProvIds,
    legalEntityType,
    stripeStatus,
    stripeRemediationItems,
    serviceFee,
    noShowFee,
    cancelFee,
    cancelWindow
  }: IBarberUserData) {
    this.uid = uid;
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.enabled = enabled;
    this.pwdNeedsReset = pwdNeedsReset;
    this.appInfo = appInfo;
    this.platformInfo = platformInfo;
    // this.avatar = avatar; // TODO: Uncomment this line after creating the BarberAvatar class
    this.currentshopId = currentshopId;
    this.barbershopIds = barbershopIds;
    this.placeBasedCareProvIds = placeBasedCareProvIds;
    this.legalEntityType = legalEntityType;
    this.stripeStatus = stripeStatus;
    this.stripeRemediationItems = stripeRemediationItems;
    this.serviceFee = serviceFee;
    this.noShowFee = noShowFee;
    this.cancelFee = cancelFee;
    this.cancelWindow = cancelWindow;
  }

  get isOnboardingComplete(): boolean {
    if (
      (this.name.display == null ||
        this.name.first == null ||
        this.name.last == null) ||
      (this.serviceFee == null ||
        this.noShowFee == null || 
        this.cancelFee == null || 
        this.cancelWindow == null) ||
      this.legalEntityType == null ||
      this.stripeStatus !== StripeStatus.TransfersEnabled
    ) {
      return false;
    } else {
      return true;
    }
  }

  async updateDb(): Promise<void> {
    const db = new BarberUserService();
    await db.updateBarberUser(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): BarberUser {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return BarberUser.fromMap(docSnap.id, data);
  }

  static fromMap(uid: string, data: { [key: string]: any }): BarberUser {
    const name: UserName = UserName.fromMap(data["name"] ?? {});
    const email: string = data["email"] ?? "";
    const phoneNumber: string | null = data["phoneNumber"] ?? null;
    const enabled: boolean = data["enabled"] ?? false;
    const pwdNeedsReset: boolean = data["pwdNeedsReset"] ?? true;
    const appInfo: AppInfo = AppInfo.fromMap(data["appInfo"] ?? {});
    const platformInfo: PlatformInfo = PlatformInfo.fromMap(data["platformInfo"] ?? {});
    // const avatar: BarberAvatar = BarberAvatarHelper.valueOf(data["avatar"] ?? "") ?? BarberAvatar.zero; // TODO: Uncomment this line after creating the BarberAvatar class
    const currentshopId: string = data["currentshopId"] ?? "";
    const barbershopIds: string[] = data["barbershopIds"] ?? [];
    const placeBasedCareProvIds: string[] = data["placeBasedCareProvIds"] ?? [];
    const legalEntityType: LegalEntityType | null = LegalEntityTypeHelper.valueOf(data["legalEntityType"] ?? null) ?? null;
    const stripeStatus: StripeStatus = StripeStatusHelper.valueOf(data["stripeStatus"] ?? "") ?? StripeStatus.Unknown;
    const stripeRemediationItems: StripeRemediationItem[] = data["stripeRemediationItems"]
      ? data["stripeRemediationItems"].map((element: any) => StripeRemediationItem.fromMap(element))
      : [];
    const serviceFee: number | null = data["serviceFee"] ?? null;
    const noShowFee: number | null = data["noShowFee"] ?? null;
    const cancelFee: number | null = data["cancelFee"] ?? null;
    const cancelWindow: number | null = data["cancelWindow"] ?? null;
    return new BarberUser({
      uid: uid,
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      enabled: enabled,
      pwdNeedsReset: pwdNeedsReset,
      appInfo: appInfo,
      platformInfo: platformInfo,
      // avatar: avatar, // TODO: Uncomment this line after creating the BarberAvatar class
      currentshopId: currentshopId,
      barbershopIds: barbershopIds,
      placeBasedCareProvIds: placeBasedCareProvIds,
      legalEntityType: legalEntityType,
      stripeStatus: stripeStatus,
      stripeRemediationItems: stripeRemediationItems,
      serviceFee: serviceFee,
      noShowFee: noShowFee,
      cancelFee: cancelFee,
      cancelWindow: cancelWindow
    });
  }

  toJson(): { [key: string]: any } {
    return {
        // "uid": this.uid, // not necessary to save UID (it is already part of the Firestore document)
      "name": this.name.toJson(),
      "email": this.email,
      "phoneNumber": this.phoneNumber,
      "enabled": this.enabled,
      "pwdNeedsReset": this.pwdNeedsReset,
      "appInfo": this.appInfo.toJson(),
      "platformInfo": this.platformInfo.toJson(),
      // "avatar": this.avatar, // TODO: Uncomment this line after creating the BarberAvatar class
      "currentshopId": this.currentshopId,
      "barbershopIds": this.barbershopIds,
      "placeBasedCareProvIds": this.placeBasedCareProvIds,
      "legalEntityType": this.legalEntityType ?? null,
      "stripeStatus": this.stripeStatus,
      "stripeRemediationItems": this.stripeRemediationItems.map((element) => element.toJson()),
      "serviceFee": this.serviceFee ? Number(this.serviceFee) : null,
      "noShowFee": this.noShowFee ? Number(this.noShowFee) : null,
      "cancelFee": this.cancelFee ? Number(this.cancelFee) : null,
      "cancelWindow": this.cancelWindow ? Number(this.cancelWindow) : null
    };
  }
}
