import { Appointment } from "@oben-core-web/models/appointment";
import { AppointmentService } from "@oben-core-web/services/appointment-service";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import isEqual from "lodash/isEqual";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";
import { DateTime } from "luxon";

const usePatientAppointments = (queryOptions: {
  clientId: string;
  statuses?: AppointmentStatus[] | null;
}) => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const prevQueryOptionsRef = useRef<{
    clientId: string;
    statuses?: AppointmentStatus[] | null;
  }>({ clientId: "", statuses: null });
  const memoizedQueryOptions = useMemo(
    () => ({
      clientId: queryOptions.clientId,

      statuses: queryOptions.statuses ?? null
    }),
    [queryOptions.clientId, queryOptions.statuses]
  );

  const fetchAppts = useCallback(async () => {
    if (
      typeof memoizedQueryOptions.clientId === "string" &&
      !!memoizedQueryOptions.clientId
    ) {
      const appointmentService = new AppointmentService();
      const appts = await appointmentService.getAllClientAppointmentsFiltered({
        clientId: memoizedQueryOptions.clientId,
        start: DateTime.now()
          .set({ hour: 0, minute: 0, second: 0 })
          .minus({ days: 7 })
          .toJSDate(),
        end: DateTime.now()
          .set({ hour: 0, minute: 0, second: 0 })
          .plus({ days: 7 })
          .toJSDate(),
        ...(memoizedQueryOptions.statuses
          ? { statuses: memoizedQueryOptions.statuses }
          : { statuses: [] })
      });
      setAppointments(appts);
    }
  }, [memoizedQueryOptions]);

  useEffect(() => {
    if (!isEqual(prevQueryOptionsRef.current, memoizedQueryOptions)) {
      prevQueryOptionsRef.current = memoizedQueryOptions;
      fetchAppts();
    }
  }, [fetchAppts, memoizedQueryOptions]);

  return { appointments };
};

export default usePatientAppointments;
