import { DocumentSnapshot } from 'firebase/firestore';

import { PlaceBasedCareProvService } from '../services/place-based-care-prov-service';
import { Address } from './address';
import { BpGoal } from './bp-goal';

export interface IPlaceBasedCareProvData {
  // common fields for all "entities"
  id: string;
  address: Address;
  businessName: string;
  enabled: boolean;
  // place-based care provider specific fields
  programBpGoal: BpGoal;
  primaryCareProvIds: string[];
  supervisingPhysId: string;
  fundsNonMedicaidCHWServices: boolean;
  chwServicePaymentAmount: number; // in cents (e.g. 1500 = $15.00)
  enrollmentIncentivePaymentAmount: number; // in cents (e.g. 1500 = $15.00)
  billingProvBusinessName: string;
  billingProvAddress: Address;
  billingProvIdentifierAtClearinghouse: string;
  billingProvTIN: string;
  billingProvNPI: string;
}

export class PlaceBasedCareProv {
  // common fields for all "entities"
  id: string;
  address: Address;
  businessName: string;
  enabled: boolean;
  // place-based care provider specific fields
  programBpGoal: BpGoal;
  primaryCareProvIds: string[];
  supervisingPhysId: string;
  fundsNonMedicaidCHWServices: boolean;
  chwServicePaymentAmount: number; // in cents (e.g. 1500 = $15.00)
  enrollmentIncentivePaymentAmount: number; // in cents (e.g. 1500 = $15.00)
  billingProvBusinessName: string;
  billingProvAddress: Address;
  billingProvIdentifierAtClearinghouse: string;
  billingProvTIN: string;
  billingProvNPI: string;
  
  constructor({
    id,
    address,
    businessName,
    enabled,
    programBpGoal,
    primaryCareProvIds,
    supervisingPhysId,
    fundsNonMedicaidCHWServices,
    chwServicePaymentAmount,
    enrollmentIncentivePaymentAmount,
    billingProvBusinessName,
    billingProvAddress,
    billingProvIdentifierAtClearinghouse,
    billingProvTIN,
    billingProvNPI,
  }: IPlaceBasedCareProvData) {
    this.id = id;
    this.address = address;
    this.businessName = businessName;
    this.enabled = enabled;
    this.programBpGoal = programBpGoal;
    this.primaryCareProvIds = primaryCareProvIds;
    this.supervisingPhysId = supervisingPhysId;
    this.fundsNonMedicaidCHWServices = fundsNonMedicaidCHWServices;
    this.chwServicePaymentAmount = chwServicePaymentAmount;
    this.enrollmentIncentivePaymentAmount = enrollmentIncentivePaymentAmount
    this.billingProvBusinessName = billingProvBusinessName;
    this.billingProvAddress = billingProvAddress;
    this.billingProvIdentifierAtClearinghouse = billingProvIdentifierAtClearinghouse;
    this.billingProvTIN = billingProvTIN;
    this.billingProvNPI = billingProvNPI;
  }

  async updateDb(): Promise<void> {
    const db = new PlaceBasedCareProvService();
    await db.updatePlaceBasedCareProv(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): PlaceBasedCareProv {
    const data = docSnap.data() as { [key: string]: any };
    if (!data) throw new Error("Document data is undefined");
    return PlaceBasedCareProv.fromMap(docSnap.id, data);
  }

  static fromMap(id: string, data: { [key: string]: any }): PlaceBasedCareProv {
    return new PlaceBasedCareProv({
      id: id,
      address: Address.fromMap(data["address"] ?? {}),
      businessName: data["businessName"] ?? "",
      enabled: data["enabled"] ?? false,
      programBpGoal: BpGoal.fromMap(data["programBpGoal"] ?? {}),
      primaryCareProvIds: data["primaryCareProvIds"] ?? [],
      supervisingPhysId: data["supervisingPhysId"] ?? "",
      fundsNonMedicaidCHWServices: data["fundsNonMedicaidCHWServices"] ?? false,
      chwServicePaymentAmount: data["chwServicePaymentAmount"] ?? 0,
      enrollmentIncentivePaymentAmount: data["enrollmentIncentivePaymentAmount"] ?? 0,
      billingProvBusinessName: data["billingProvBusinessName"] ?? "",
      billingProvAddress: Address.fromMap(data["billingProvAddress"] ?? {}),
      billingProvIdentifierAtClearinghouse: data["billingProvIdentifierAtClearinghouse"] ?? "",
      billingProvTIN: data["billingProvTIN"] ?? "",
      billingProvNPI: data["billingProvNPI"] ?? "",
    });
  }

  toJson(): { [key: string]: any } {
    return {
      // "id": this.id, // not necessary to save ID (it is already part of the Firestore document)
      "address": this.address.toJson(),
      "businessName": this.businessName,
      "enabled": this.enabled,
      "programBpGoal": this.programBpGoal.toJson(),
      "primaryCareProvIds": this.primaryCareProvIds,
      "supervisingPhysId": this.supervisingPhysId,
      "fundsNonMedicaidCHWServices": this.fundsNonMedicaidCHWServices,
      "chwServicePaymentAmount": Number(this.chwServicePaymentAmount),
      "enrollmentIncentivePaymentAmount": Number(this.enrollmentIncentivePaymentAmount),
      "billingProvBusinessName": this.billingProvBusinessName,
      "billingProvAddress": this.billingProvAddress.toJson(),
      "billingProvIdentifierAtClearinghouse": this.billingProvIdentifierAtClearinghouse,
      "billingProvTIN": this.billingProvTIN,
      "billingProvNPI": this.billingProvNPI,
    };
  }
}