import { Claim } from "@oben-core-web/models/claim";
// import { ClaimStatus } from "@oben-core-web/models/claim-status-change";
import { ClaimService } from "@oben-core-web/services/claim-service";
import { useCallback, useEffect, useRef, useState } from "react";

const useClaims = ({
  placeBasedCareProvId,
  fetchInitially = false
}: {
  placeBasedCareProvId: string;
  fetchInitially?: boolean;
}) => {
  const wasFetched = useRef(false);
  const [claims, setClaims] = useState<Claim[]>([]);

  const fetchClaims = useCallback(async () => {
    if (!placeBasedCareProvId) return;
    const claimService = new ClaimService();
    const claimList = await claimService.getAllClientClaimsFiltered({
      placeBasedCareProvId,
      statuses: [],
      // statuses: [
      //   ClaimStatus.New,
      //   ClaimStatus.NeedsFurtherReview,
      //   ClaimStatus.ApprovedByPBCP,
      //   ClaimStatus.ApprovedByPatient,
      //   ClaimStatus.ClaimPaidByInsurer,
      //   ClaimStatus.Denied,
      //   ClaimStatus.Submitted
      // ],
      start: null,
      end: null
    });
    setClaims(claimList);
    return claimList;
  }, [placeBasedCareProvId]);

  useEffect(() => {
    if (fetchInitially && !wasFetched.current) {
      wasFetched.current = true;
      fetchClaims();
    }
  }, [fetchClaims, fetchInitially]);

  return { claims, fetchClaims };
};

export default useClaims;
