import { DocumentSnapshot } from "firebase/firestore";

import { BillableItemService } from "../services/billable-item-service";
import { BillStatus, BillStatusHelper, BillStatusChange } from "./bill-status-change";
import { StripePmtStatusChange } from "./stripe-pmt-status-change";
import { UserName } from "./user-name";

export enum BillableItemType {
  UnknownService = "UnknownService",
  PrimaryService = "PrimaryService",
  CHWService = "CHWService",
  EnrollmentIncentive = "EnrollmentIncentive",
}

interface IBillableItemTypeData {
  localizedLabel: string;
  englishDescription: string;
}

export const billableItemTypeData: Record<BillableItemType, IBillableItemTypeData> = {
  [BillableItemType.UnknownService]: {
    localizedLabel: 'core_label_unknown_service',
    englishDescription: 'Unknown Service', // english only
  },
  [BillableItemType.PrimaryService]: {
    localizedLabel: 'core_label_primary_service_haircut',
    englishDescription: 'Primary Service: Haircut', // english only
  },
  [BillableItemType.CHWService]: {
    localizedLabel: 'core_label_chw_service_screening_education',
    englishDescription: 'CHW Service: Screening & Education', // english only
  },
  [BillableItemType.EnrollmentIncentive]: {
    localizedLabel: 'core_label_enrollment_incentive',
    englishDescription: 'Enrollment Incentive', // english only
  }
}

export class BillableItemTypeHelper {
  static valueOf(searchString: string | null): BillableItemType | null {
    if (searchString === null) {
      return null;
    }
    return Object.values(BillableItemType).includes(
      searchString as BillableItemType
    ) //
      ? (searchString as BillableItemType)
      : null;
  }
}

/// BillableItems represent services that have been delivered by a CHW (eg. Barber) and require payment to them.
/// BillableItems are created by the CHW (eg. Barber) and are maintained in a subcollection on BillableEvent.
/// NOTE: Although some BillableItems will have a corresponding Claim (which demands payment from the insurance company),
///       they are used for distict/separate purposes and are not directly linked.
///* Due to a Firestore limitation, when working with subcollections, for queries to work we must also store
///* the Firestore document id within the document itself (stored as "modelId").
export interface IBillableItemData {
  modelId: string;
  billableEventId: string;
  clientId: string;
  clientName: UserName;
  barberId: string | null;
  barberName: UserName | null;
  serviceDate: Date;
  placeBasedCareProvId: string;
  billableItemType: BillableItemType;
  description: string; // in English (from the english localization of the BillableItemType)
  amount: number; // in cents (e.g. 1500 = $15.00)
  currentStatus: BillStatus;
  billStatusChanges: BillStatusChange[];
  stripePmtStatusChanges: StripePmtStatusChange[];
}

export class BillableItem {
  modelId: string;
  billableEventId: string;
  clientId: string;
  clientName: UserName;
  barberId: string | null;
  barberName: UserName | null;
  serviceDate: Date;
  placeBasedCareProvId: string;
  billableItemType: BillableItemType;
  description: string; // in English (from the english localization of the BillableItemType)
  amount: number; // in cents (e.g. 1500 = $15.00)
  currentStatus: BillStatus;
  billStatusChanges: BillStatusChange[];
  stripePmtStatusChanges: StripePmtStatusChange[];

  constructor({
    modelId,
    billableEventId,
    clientId,
    clientName,
    barberId,
    barberName,
    serviceDate,
    placeBasedCareProvId,
    billableItemType,
    description,
    amount,
    currentStatus,
    billStatusChanges = [],
    stripePmtStatusChanges = []
  }: IBillableItemData) {
    this.modelId = modelId;
    this.billableEventId = billableEventId;
    this.clientId = clientId;
    this.clientName = clientName;
    this.barberId = barberId;
    this.barberName = barberName;
    this.serviceDate = serviceDate;
    this.placeBasedCareProvId = placeBasedCareProvId;
    this.billableItemType = billableItemType;
    this.description = description;
    this.amount = amount;
    this.currentStatus = currentStatus;
    this.billStatusChanges = billStatusChanges;
    this.stripePmtStatusChanges = stripePmtStatusChanges;
  }

  async updateDb(): Promise<void> {
    const db = new BillableItemService();
    await db.updateBillableItem(this);
  }

  static fromFirestore(docSnap: DocumentSnapshot): BillableItem {
    const data = docSnap.data() as { [key: string]: any };
    return BillableItem.fromMap(docSnap.id, data);
  }

  static fromMap(modelId: string, data: { [key: string]: any }): BillableItem {
    return new BillableItem({
      modelId,
      billableEventId: data["billableEventId"] ?? "",
      clientId: data["clientId"] ?? "",
      clientName: UserName.fromMap(data["clientName"] ?? {}),
      barberId: data["barberId"] ?? null,
      barberName: data["barberName"] //
        ? UserName.fromMap(data["barberName"])
        : null,
      serviceDate: data["serviceDate"] //
        ? new Date(data["serviceDate"])
        : new Date(1, 0, 1), // default to 1/1/0001
      placeBasedCareProvId: data["placeBasedCareProvId"] ?? "",
      billableItemType: BillableItemTypeHelper.valueOf(data["billableItemType"] ?? "") ?? BillableItemType.UnknownService,
      description: data["description"] ?? "",
      amount: data["amount"] ?? 0,
      currentStatus: BillStatusHelper.valueOf(data["currentStatus"] ?? "") ?? BillStatus.Unknown,
      billStatusChanges: data["billStatusChanges"] //
        ? data["billStatusChanges"].map((element: any) => BillStatusChange.fromMap(element))
        : [],
      stripePmtStatusChanges: data["stripePmtStatusChanges"] //
        ? data["stripePmtStatusChanges"].map((element: any) => StripePmtStatusChange.fromMap(element))
        : []
    });
  }

  toJson(): { [key: string]: any } {
    return {
      "modelId": this.modelId, // with subcollections we must save ID within the Firestore document itself
      "billableEventId": this.billableEventId,
      "clientId": this.clientId,
      "clientName": this.clientName.toJson(),
      "barberId": this.barberId,
      "barberName": this.barberName ? this.barberName.toJson() : null,
      "serviceDate": this.serviceDate.toISOString(),
      "placeBasedCareProvId": this.placeBasedCareProvId,
      "billableItemType": this.billableItemType,
      "description": this.description,
      "amount": Number(this.amount),
      "currentStatus": this.currentStatus,
      "billStatusChanges": this.billStatusChanges.map((element) => element.toJson()),
      "stripePmtStatusChanges": this.stripePmtStatusChanges.map((element) => element.toJson()),
    };
  }
}
