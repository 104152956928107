import React, { useMemo, useState } from "react";
import Chart from "react-apexcharts";
import useBpReadings from "@/hooks/useBpReadings";
import useClientCarePlans from "@/hooks/useClientCarePlans";
import {
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { CarePlan } from "@oben-core-web/models/care-plan";
import { CarePlanService } from "@oben-core-web/services/care-plan-service";
import { BpGoal } from "@oben-core-web/models/bp-goal";
import { useForm } from "react-hook-form";
import { BpReading } from "@oben-core-web/models/bp-reading";
import { UserType } from "@oben-core-web/constants/core-enums";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";
import { ClientUserService } from "@oben-core-web/services/client-user-service";

interface IBPChart {
  canEditGoal?: boolean;
  patientId?: string;
  height?: number | string;
  width?: number | string;
  projectedGoal?: BpGoal;
}

const BPChart = ({
  canEditGoal = false,
  patientId,
  projectedGoal,
  ...rest
}: IBPChart) => {
  const { isOpen, onToggle } = useDisclosure();
  const { currentCarePlan } = useClientCarePlans(patientId);
  const { bpReadings } = useBpReadings(patientId);
  const [activeFilter, setActiveFilter] = useState<
    "all" | "barbers" | "patients"
  >("all");
  const { placeBasedCareProvider } = usePlaceBasedCareProvider();

  const filteredBpReadings = useMemo(() => {
    return bpChartFilters[activeFilter](bpReadings);
  }, [activeFilter, bpReadings]);

  // put BPReadings and BPGoals into a single list, sort by date
  const sortedData: (BpReading | BpGoal)[] = [
    ...filteredBpReadings,
    ...(currentCarePlan?.bpGoals
      ? currentCarePlan.bpGoals
      : [placeBasedCareProvider!.programBpGoal]),
    ...(projectedGoal ? [projectedGoal] : [])
  ].sort((a, b) => {
    const aDate = a instanceof BpReading ? a.readingDate : a.setDate;
    const bDate = b instanceof BpReading ? b.readingDate : b.setDate;
    return aDate < bDate ? -1 : 1;
  });

  // fill in gaps where we have a reading that doesn't match a goal date and vice versa
  const combinedSeries = sortedData.reduce(
    (a, c) => {
      if (c instanceof BpReading) {
        const x = Date.parse(c.readingDate.toString());
        a.systolic.push({
          x,
          y: c.systolic,
          source: c.recorderType
        });
        a.diastolic.push({
          x,
          y: c.diastolic,
          source: c.recorderType
        });
        const lastGoalSystolicValue =
          a.goalSystolic[a.goalSystolic.length - 1]?.y ?? 0;
        const lastGoalDiastolicValue =
          a.goalDiastolic[a.goalDiastolic.length - 1]?.y ?? 0;
        a.goalSystolic.push({ x, y: Number(lastGoalSystolicValue) });
        a.goalDiastolic.push({ x, y: Number(lastGoalDiastolicValue) });
      } else {
        const x = Date.parse(c.setDate.toString());
        a.goalSystolic.push({ x, y: Number(c.systolic) });
        a.goalDiastolic.push({ x, y: Number(c.diastolic) });
        const lastSystolicValue = a.systolic[a.systolic.length - 1]?.y ?? 0;
        const lastDiastolicValue = a.diastolic[a.diastolic.length - 1]?.y ?? 0;
        a.systolic.push({
          x,
          y: lastSystolicValue,
          source: a.systolic[a.systolic.length - 1]?.source ?? UserType.Unknown
        });
        a.diastolic.push({
          x,
          y: lastDiastolicValue,
          source:
            a.diastolic[a.diastolic.length - 1]?.source ?? UserType.Unknown
        });
      }
      return a;
    },
    {
      systolic: [] as { x: number; y: number; source: UserType }[],
      diastolic: [] as { x: number; y: number; source: UserType }[],
      goalSystolic: [] as { x: number; y: number }[],
      goalDiastolic: [] as { x: number; y: number }[]
    }
  );

  // assign each series to a plotted line or area on the graph
  const chartSeries = useMemo(() => {
    const bpSeries: any = [
      {
        name: "Systolic",
        data: combinedSeries.systolic,
        type: "line"
        // dataLabels: {
        //   enabled: true,
        //   formatter: (val: any) => {
        //     return val.source === UserType.Client
        //       ? `${val} (Patient Reading)`
        //       : val.source === UserType.Barber
        //       ? `${val} (Barber Reading)`
        //       : `${val} (Pharmacist Reading)`;
        //   }
        // }
      },
      {
        name: "Diastolic",
        data: combinedSeries.diastolic,
        type: "line"
        // dataLabels: {
        //   enabled: true,
        //   formatter: (val: any) => {
        //     return val.source === UserType.Client
        //       ? `${val} (Patient Reading)`
        //       : val.source === UserType.Barber
        //       ? `${val} (Barber Reading)`
        //       : `${val} (Pharmacist Reading)`;
        //   }
        // }
      }
    ];
    if (currentCarePlan) {
      const goalSeries = [
        {
          name: "Goal Systolic",
          data: combinedSeries.goalSystolic,
          type: "area",
          dataLabels: {
            enabled: false
          }
        },
        {
          name: "Goal Diastolic",
          data: combinedSeries.goalDiastolic,
          type: "area",
          dataLabels: {
            enabled: false
          }
        }
      ];
      return bpSeries.concat(goalSeries);
    }
    return bpSeries as ApexAxisChartSeries;
  }, [combinedSeries, currentCarePlan]);

  return (
    <div
      className={"bp-chart"}
      style={{ height: rest.height ?? "100%", position: "relative" }}
    >
      {!canEditGoal && (
        <Select
          value={activeFilter}
          onChange={(e) => {
            setActiveFilter(e.target.value as "all" | "barbers" | "patients");
          }}
          size={"xs"}
          w={"10rem"}
          pos={"absolute"}
          top={0}
          right={canEditGoal ? "6.5rem" : 0}
          zIndex={10}
        >
          <option value={"all"}>All Readings</option>
          <option value={"barbers"}>Barber Readings</option>
          <option value={"patients"}>Patient Readings</option>
        </Select>
      )}
      <Chart
        type='line'
        width={"82%"}
        height={"50%"}
        {...rest}
        options={{
          title: {
            text: "Blood Pressure"
          },
          colors: ["#ffaf00", "#196bff", "#ffaf0095", "#196bff95"],
          chart: {
            id: "vuechart-example",
            toolbar: {
              show: true,
              tools: {
                download: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
                ...(canEditGoal
                  ? {
                      customIcons: [
                        {
                          icon: '<div style="display: flex; width: 5.5rem; position: absolute; right: 0; font-size:smaller; z-index: 1; cursor:pointer;">Edit BP Goal</div>',
                          index: 0,
                          title: "Edit BP Goal",
                          class: "custom-icon",
                          click: function () {
                            onToggle();
                          }
                        }
                      ]
                    }
                  : {})
              }
            },
            zoom: {
              enabled: false
            }
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeFormatter: {
                year: "yyyy",
                month: "MMM 'yy",
                day: "M/dd/yy"
              }
            },
            tooltip: {
              enabled: false
            }
            // categories: readingDates
          },
          yaxis: [
            {
              min: 0,
              max: 200,
              stepSize: 20
            },
            {
              min: 0,
              max: 200,
              stepSize: 20,
              opposite: true
            }
          ],
          noData: {
            text: "No blood pressure readings for this patient",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "14px",
              fontFamily: undefined
            }
          },
          stroke: {
            curve: "smooth"
          },
          // dataLabels: {
          //   enabled: false,
          //   enabledOnSeries: [0, 1] // Show values on markers
          // },
          markers: {
            // shape: "circle",
            // size: 5,
            discrete: [
              ...combinedSeries.systolic.map((s, i) => ({
                seriesIndex: 0,
                dataPointIndex: i,
                size: 4,
                strokeColor: "#ffaf00",
                fillColor: "#ffaf00",
                shape: (s.source === UserType.Client
                  ? "circle"
                  : "triangle") as ApexMarkerShape
              })),
              ...combinedSeries.diastolic.map((d, i) => ({
                seriesIndex: 1,
                dataPointIndex: i,
                size: 4,
                strokeColor: "#196bff",
                fillColor: "#196bff",
                shape: (d.source === UserType.Client
                  ? "circle"
                  : "triangle") as ApexMarkerShape
              }))
            ]
          },
          tooltip: {
            x: {
              format: "dd MMM yyyy"
            },
            y: {
              formatter: (val: any, opts: any) => {
                const isBpReadingSeries = opts.seriesIndex <= 1;
                if (!isBpReadingSeries) {
                  return val;
                }
                switch (opts.seriesIndex) {
                  case 0: {
                    const source =
                      combinedSeries.systolic[opts.dataPointIndex].source;
                    return source === UserType.Client
                      ? `${val} (Patient Reading)`
                      : source === UserType.Barber
                      ? `${val} (Barber Reading)`
                      : `${val} (Pharmacist Reading)`;
                  }
                  case 1: {
                    const source =
                      combinedSeries.diastolic[opts.dataPointIndex].source;
                    return source === UserType.Client
                      ? `${val} (Patient Reading)`
                      : source === UserType.Barber
                      ? `${val} (Barber Reading)`
                      : `${val} (Pharmacist Reading)`;
                  }
                  default:
                    return val;
                }
              }
            },
            marker: {
              show: false
            }
          }
        }}
        series={chartSeries}
      />
      {currentCarePlan && (
        <BPGoalFormModal
          carePlan={currentCarePlan}
          isOpen={isOpen}
          onToggle={onToggle}
        />
      )}
    </div>
  );
};

export default BPChart;

const BPGoalFormModal = ({
  carePlan,
  isOpen,
  onToggle
}: {
  carePlan: CarePlan;
  isOpen: boolean;
  onToggle: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { bpGoals } = carePlan;
  const lastBpGoal = bpGoals.sort((a, b) =>
    a.setDate > b.setDate ? -1 : 1
  )[0];
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      systolic: lastBpGoal?.systolic,
      diastolic: lastBpGoal?.diastolic
    }
  });
  const submissionHandler = async (data: {
    systolic: number;
    diastolic: number;
  }) => {
    const { dirtyFields } = formState;
    if (dirtyFields) {
      setLoading(true);
      const bpGoal = new BpGoal({
        systolic: data.systolic,
        diastolic: data.diastolic,
        setDate: new Date()
      });
      const carePlanService = new CarePlanService();
      const clientUserService = new ClientUserService();
      const newBpGoals = carePlan.bpGoals.concat([bpGoal]);
      carePlan.bpGoals = newBpGoals;
      const clientUser = await clientUserService.getClientUser(
        carePlan.clientId
      );
      clientUser.currentBpGoal = bpGoal;
      await carePlanService
        .updateCarePlan(carePlan)
        .then(async () => {
          toast({ status: "success", description: "New BP goal set" });
          await clientUserService.updateClientUser(clientUser);
          setLoading(false);
          onToggle();
        })
        .catch((error) => {
          console.log("Failed to create BP goal", error);
          toast({ status: "error", description: "Failed to set new BP goal" });
          setLoading(false);
        });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(submissionHandler)}>
          <ModalHeader
            display='flex'
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text>Set New Blood Pressure Goal</Text>
            <CloseButton onClick={onToggle} />
          </ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Systolic</FormLabel>
              <Input type='number' {...register("systolic")} />
            </FormControl>
            <FormControl>
              <FormLabel>Diastolic</FormLabel>
              <Input type='number' {...register("diastolic")} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onToggle}>Cancel</Button>
            <Button type='submit' isDisabled={loading}>
              {loading ? <Spinner /> : "Save"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const bpChartFilters = {
  all: (bpReadings: BpReading[]) => {
    return bpReadings;
  },
  barbers: (bpReadings: BpReading[]) => {
    return bpReadings.filter((bp) => bp.recorderType === UserType.Barber);
  },
  patients: (bpReadings: BpReading[]) => {
    return bpReadings.filter((bp) => bp.recorderType === UserType.Client);
  }
};
