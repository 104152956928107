import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
  Box,
  VStack,
  Flex,
  HStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  IconButton,
  Tooltip,
  Spinner,
  // Menu,
  // MenuButton,
  // MenuItem,
  // MenuList,
  Card,
  CloseButton
} from "@chakra-ui/react";
import { DateTime, Interval } from "luxon";
import IndexSearch from "./IndexSearch";
import useWebUsers from "@/hooks/useWebUsers";
import useBarbershops from "@/hooks/useBarbershops";
import appointmentSchema from "@/resolvers/appointment";
import { yupResolver } from "@hookform/resolvers/yup";
import { IUserNameData, UserName } from "@oben-core-web/models/user-name";
import useCustomWorkingHours from "@/hooks/useCustomWorkingHours";
import { convertToLocalTZ } from "@/lib/timezone";
import { Appointment } from "@oben-core-web/models/appointment";
import { AppointmentService } from "@oben-core-web/services/appointment-service";
import { Link } from "react-router-dom";
import useCurrentUser from "@/hooks/useCurrentUser";
import { BillingTransactionService } from "@oben-core-web/services/billing-transaction-service";
import {
  AppointmentStatus,
  AppointmentStatusChange
} from "@oben-core-web/models/appointment-status-change";
import {
  // ChevronDownIcon,
  EditIcon
} from "@chakra-ui/icons";
// import { MdOutlinePersonOff } from "react-icons/md";
// import { FaCalendarTimes } from "react-icons/fa";
// import { FaFileMedical } from "react-icons/fa6";
// import { FaUserAltSlash } from "react-icons/fa";
import useBarber from "@/hooks/useBarber";
import HoverHelp from "../atoms/HoverHelp";
import { BarbershopService } from "@oben-core-web/services/barbershop-service";
import { Barbershop } from "@oben-core-web/models/barbershop";
import useClientUser from "@/hooks/useClientUser";
// import { UserType } from "@oben-core-web/constants/core-enums";
import { startCase } from "lodash";
import useBillableEvent from "@/hooks/useBillableEvent";
import { UserType } from "@oben-core-web/constants/core-enums";
import { BarberUserService } from "@oben-core-web/services/barber-user-service";

interface IAppointmentForm {
  id?: string;
  startDateTime: Date;
  length: number;
  pharmacistId: string;
  billableEventId: string;
  currentStatus: AppointmentStatus;
  statusChanges: AppointmentStatusChange[];
  barbershopId: string;
  barbershopName: string;
  pharmacistName: UserName;
  barberId: string;
  clientId: string;
  clientName: UserName;
  barberName: UserName;
  outreachId: string;
}

interface ISchedulerAppointmentFormProps {
  dialogOpen: boolean;
  appointment: IAppointmentForm;
  disableFormActions: boolean;
  startClientAppointment: (clientId: string) => Promise<void>;
  toggleDialog: () => void;
  onAppointmentScheduled?: (appointment: Appointment) => void;
}

const SchedulerAppointmentForm = React.memo(
  ({
    dialogOpen,
    appointment,
    disableFormActions,
    startClientAppointment,
    toggleDialog,
    onAppointmentScheduled
  }: ISchedulerAppointmentFormProps) => {
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const { currentUser } = useCurrentUser();
    const { webUsers } = useWebUsers();
    const [barbershopOptions, setBarbershopOptions] = useState<Barbershop[]>(
      []
    );
    const { barbershops } = useBarbershops();
    const { barber, fetchBarber } = useBarber({
      uid: appointment?.barberId ?? "",
      initialFetch: !!appointment?.barberId
    });
    const { billableData, refetch: refetchBillableData } = useBillableEvent(
      appointment?.billableEventId ?? ""
    );
    const { clientUser, fetchClientUser } = useClientUser(
      appointment?.clientId ?? ""
    );
    const actionDisclosure = useDisclosure();
    const [actionType, setActionType] = useState<
      "Completed" | "No-show" | "Cancelled"
    >();
    const [editMode, setIsEditMode] = useState(false);
    const { fetchCustomWorkingHours } = useCustomWorkingHours();
    const isExistingAppointment = !!appointment?.id;
    const {
      handleSubmit,
      setValue,
      reset,
      control,
      watch,
      formState,
      register
    } = useForm({
      defaultValues: {
        id: appointment?.id || "",
        startDateTime: appointment?.startDateTime || new Date(),
        length: appointment?.length || 30,
        billableEventId: appointment?.billableEventId || "",
        pharmacistId: appointment?.pharmacistId || "",
        pharmacistName: appointment?.pharmacistName || {
          first: "",
          last: "",
          display: ""
        },
        barbershopId: appointment?.barbershopId || "",
        barberId: appointment?.barberId || "",
        clientId: appointment?.clientId || "",
        clientName:
          appointment?.clientName ??
          ({
            first: "",
            last: "",
            display: ""
          } as IUserNameData),
        barberName:
          appointment?.barberName ??
          ({
            first: "",
            last: "",
            display: ""
          } as IUserNameData),
        currentStatus: appointment?.currentStatus
          ? appointment.currentStatus === AppointmentStatus.LateCanceled
            ? AppointmentStatus.Canceled
            : appointment.currentStatus
          : AppointmentStatus.Unknown,
        outreachId: appointment?.outreachId ?? ""
      } as any,
      resolver: yupResolver(appointmentSchema)
    });

    const [localStartDateTime, setLocalStartDateTime] = useState(
      appointment?.startDateTime
        ? DateTime.fromJSDate(appointment.startDateTime).toFormat(
            "yyyy-MM-dd'T'HH:mm"
          )
        : DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm")
    );

    useEffect(() => {
      const resetForm = async () => {
        if (appointment) {
          if (appointment.id) {
            setIsEditMode(false);
          } else {
            setIsEditMode(true);
          }
          if (appointment.barberId) {
            const barber = await fetchBarber(appointment.barberId);
            const barberWorkplaces = barbershops.filter((bs) =>
              barber.barbershopIds.includes(bs.id)
            );
            setBarbershopOptions(barberWorkplaces);
          }
          if (appointment.clientId) {
            await fetchClientUser(appointment.clientId);
          }
          setLocalStartDateTime(
            DateTime.fromJSDate(appointment.startDateTime).toFormat(
              "yyyy-MM-dd'T'HH:mm"
            )
          );
          reset(appointment, {
            // keepDefaultValues: true
            // keepDirtyValues: true
          });

          let status = AppointmentStatus.Unknown;
          if (appointment?.currentStatus) {
            status = appointment.currentStatus;
          } else if (appointment?.statusChanges?.length > 0) {
            status =
              appointment.statusChanges[appointment.statusChanges?.length - 1]
                .status;
          }
          if (status === AppointmentStatus.LateCanceled) {
            status = AppointmentStatus.Canceled;
          }
          setValue(
            "currentStatus",
            appointment.currentStatus as AppointmentStatus
          );
        }
      };
      resetForm();
    }, [
      // barber,
      appointment,
      reset,
      fetchBarber,
      fetchClientUser,
      barbershops,
      setValue
      // clientUser
    ]);

    const handleFormSubmit = async (data: IAppointmentForm) => {
      try {
        setLoading(true);
        const appointmentInterval = Interval.fromDateTimes(
          DateTime.fromJSDate(new Date(localStartDateTime)),
          DateTime.fromJSDate(new Date(localStartDateTime)).plus({
            minutes: data.length
          })
        );
        if (new Date(localStartDateTime) < new Date()) {
          toast({
            status: "error",
            description: "Cannot create an appointment in the past"
          });
          setLoading(false);
          return;
        }
        // validation helpers
        const setDateTime = (date: Date, hour: number, minute: number) => {
          return DateTime.fromJSDate(new Date(date))
            .set({ hour, minute })
            .toJSDate();
        };

        const validateWorkingHours = (
          workingHours: { start: Date; end: Date }[]
        ) => {
          return workingHours.some((wh) => {
            const workingHourInterval = Interval.fromDateTimes(
              DateTime.fromJSDate(convertToLocalTZ(wh.start as Date)),
              DateTime.fromJSDate(convertToLocalTZ(wh.end as Date))
            );
            return (
              workingHourInterval.start! <= appointmentInterval.start! &&
              workingHourInterval.end! >= appointmentInterval.end!
            );
          });
        };
        // fetch relevant working hours
        const dayCustomHours = await fetchCustomWorkingHours({
          userId: data.pharmacistId,
          start: setDateTime(new Date(localStartDateTime), 0, 0),
          end: setDateTime(new Date(localStartDateTime), 23, 59)
        });
        const pharmacist = webUsers.find((wu) => wu.uid === data.pharmacistId)!;
        const pharmacistWorkingHours =
          pharmacist.defaultWorkingHours
            .filter(
              (wh) =>
                wh.dayOfWeek ===
                DateTime.fromJSDate(new Date(localStartDateTime)).weekday
            )
            .map((dwh) => ({
              start: setDateTime(
                new Date(localStartDateTime),
                dwh.startTime.getHours(),
                dwh.startTime.getMinutes()
              ),
              end: setDateTime(
                new Date(localStartDateTime),
                dwh.endTime.getHours(),
                dwh.endTime.getMinutes()
              )
            })) ?? [];

        const hasValidCustomHours =
          !!dayCustomHours.length &&
          validateWorkingHours(
            dayCustomHours.map((cwh) => ({ start: cwh.start!, end: cwh.end! }))
          );
        const hasValidDefaultHours =
          !dayCustomHours.length &&
          !!pharmacistWorkingHours.length &&
          validateWorkingHours(pharmacistWorkingHours);

        if (!dayCustomHours.length && !pharmacistWorkingHours.length) {
          toast({
            description: "Pharmacist is not working that day",
            status: "error"
          });
        }
        if (
          hasValidCustomHours ||
          (dayCustomHours.length === 0 && hasValidDefaultHours)
        ) {
          const appointmentService = new AppointmentService();
          const appointment = new Appointment({
            ...data,
            id: isExistingAppointment ? data.id! : "",
            clientName: new UserName(data.clientName),
            pharmacistName: pharmacist.name,
            date: new Date(localStartDateTime),
            statusChanges: data.statusChanges ?? [],
            billableEventId: "",
            barberName: new UserName(data.barberName),
            barbershopName: barbershops.find((b) => b.id === data.barbershopId)!
              .businessName,
            outreachId: "",
            currentStatus: data.currentStatus
          });
          if (isExistingAppointment) {
            await appointmentService.updateAppointment(appointment).then(() => {
              toast({ description: "Appointment updated!", status: "success" });
              reset();
              onAppointmentScheduled && onAppointmentScheduled(appointment);
              toggleDialog();
            });
          } else {
            appointment.statusChanges = [
              new AppointmentStatusChange({
                status: AppointmentStatus.New,
                date: new Date(),
                editorId: pharmacist.uid,
                editorType: pharmacist.userType,
                details: "Appointment created"
              })
            ];
            appointment.currentStatus = AppointmentStatus.New;
            await appointmentService.addAppointment(appointment).then(() => {
              toast({
                description: "Appointment created!",
                status: "success"
              });
              setLoading(false);
              toggleDialog();
              onAppointmentScheduled && onAppointmentScheduled(appointment);
            });
          }
        } else {
          toast({
            description: "Pharmacist is not working at that time",
            status: "error"
          });
        }
      } catch (e) {
        console.log("create appt error", e);
        toast({
          description: "Something went wrong. Please try again.",
          status: "error"
        });
      } finally {
        setLoading(false);
      }
    };

    const handlePatientSearchResultClick = async ({ id, name }: any) => {
      setValue("clientId", id, { shouldDirty: true });
      setValue("clientName", name, { shouldDirty: true });
      await fetchClientUser(id);
    };

    const handleBarberSearchResultClick = async ({
      id,
      name,
      barbershopIds
    }: any) => {
      setValue("barberId", id);
      setValue("barberName", name);
      const barbershopService = new BarbershopService();
      const barbershops = await barbershopService.getBarbershops(barbershopIds);
      setBarbershopOptions(barbershops);
    };

    const customTransformItems = (items: any[]) => {
      return items.map((item) => ({
        ...item,
        label: item.name.first + " " + item.name.last,
        name: item.name,
        id: item.objectID.split("/")[1] ?? item.objectID,
        ...(item.dob
          ? {
              dob: DateTime.fromJSDate(new Date(item.dob))
                .toUTC()
                .toFormat("DD")
            }
          : {}),
        email: item.email
      }));
    };

    const updateApptStatus = async (
      e: React.ChangeEvent<HTMLSelectElement>
    ) => {
      if (!appointment.id) return;
      let status = e.target.value as AppointmentStatus;
      if (barber?.cancelWindow && status === AppointmentStatus.Canceled) {
        const isLateCancel =
          DateTime.fromJSDate(appointment.startDateTime).minus({
            days: barber.cancelWindow
          }) < DateTime.now();
        if (isLateCancel) {
          status = AppointmentStatus.LateCanceled;
        }
      }

      if (
        status !== AppointmentStatus.Confirmed &&
        status !== AppointmentStatus.New
      ) {
        if (
          status === AppointmentStatus.Canceled ||
          status === AppointmentStatus.LateCanceled
        ) {
          setActionType("Cancelled");
          actionDisclosure.onToggle();
        } else if (status === AppointmentStatus.NoShow) {
          setActionType("No-show");
          actionDisclosure.onToggle();
        }
        return;
      }

      const newStatus = new AppointmentStatusChange({
        status,
        date: new Date(),
        editorId: currentUser!.uid,
        editorType: currentUser!.userType,
        details: ""
      });

      const apptService = new AppointmentService();
      const appt = Appointment.fromMap(appointment.id, {
        ...appointment,
        date: appointment.startDateTime,
        statusChanges: [...appointment.statusChanges, newStatus],
        currentStatus: status
      });
      await apptService
        .updateAppointment(appt)
        .catch(() => {
          toast({
            status: "error",
            description: "Failed to update appointment status"
          });
        })
        .then(() => {
          // AppointmentTransactionService.createBillableFromApptStatus({
          //   appointmentId: appt.id,
          //   placeBasedCareProvId: currentUser!.placeBasedCareProvId ?? "",
          //   editorId: currentUser!.uid,
          //   statusChange: newStatus.status,
          //   approveImmediately: false
          // });
          if (
            newStatus.status !== AppointmentStatus.Confirmed &&
            newStatus.status !== AppointmentStatus.New
          ) {
            BillingTransactionService.submitToProgramManager({
              appointmentId: appt.id,
              placeBasedCareProvId: currentUser!.placeBasedCareProvId ?? "",
              editorId: currentUser!.uid,
              statusChange: newStatus.status
            });
          }
        })
        .then(() => {
          toast({
            status: "success",
            description: "Appointment status updated!"
          });
          toggleDialog();
        })
        .catch((e) => {
          console.log(
            "Appointment Transaction Service Error:  Failed to create billable event from status",
            e.message
          );
          toast({
            status: "error",
            description: "Failed to create billable item from status change"
          });
        });
    };
    const isBillingComplete = !!billableData?.isSubmitted;
    // const hasBillableEvent = !!appointment.billableEventId;
    const appointmentDateHasPast =
      DateTime.fromJSDate(appointment?.startDateTime) < DateTime.now();
    const canSubmitServicePayment = useMemo(
      () => appointment?.currentStatus === AppointmentStatus.Completed,
      [appointment]
    );
    const isAfterApptStart = useMemo(
      () => DateTime.fromJSDate(appointment?.startDateTime) <= DateTime.now(),
      [appointment]
    );
    const lastApptStatus = useMemo(() => {
      let status: AppointmentStatus = AppointmentStatus.Unknown;
      if (appointment?.currentStatus) {
        status = appointment.currentStatus;
      } else if (appointment?.statusChanges) {
        status =
          appointment.statusChanges[appointment.statusChanges?.length - 1]
            .status;
      }
      if (status === AppointmentStatus.LateCanceled) {
        status = AppointmentStatus.Canceled;
      }
      return status;
    }, [appointment]);
    formState.isValid;
    return (
      <>
        <AppointmentActionDialog
          action={actionType}
          appointment={appointment}
          startClientAppointment={async (clientId: string) => {
            actionDisclosure.onClose();
            startClientAppointment(clientId);
          }}
          isOpen={actionDisclosure.isOpen}
          onClose={() => {
            setActionType(undefined);
            actionDisclosure.onClose();
          }}
          onConfirm={async () => {
            if (appointment.id) {
              const appontmentService = new AppointmentService();
              const requeriedAppt = await appontmentService.getAppointment(
                appointment.id
              );
              refetchBillableData(requeriedAppt.billableEventId);
            }
          }}
          // closeScheduleForm={toggleDialog}
        />

        <Modal
          isOpen={dialogOpen}
          onClose={() => {
            setBarbershopOptions([]);
            reset();
            toggleDialog();
          }}
          size='xl'
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader pb={0}>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                {isExistingAppointment ? (
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    w={"full"}
                  >
                    <Flex>
                      <Text>Appointment with &nbsp;</Text>
                      <Link
                        to={`/patient/${appointment.clientId}`}
                        style={{ textDecoration: "underline" }}
                      >
                        {appointment.clientName.first +
                          " " +
                          appointment.clientName.last}
                      </Link>
                    </Flex>
                    <Flex>
                      <Tooltip
                        label={
                          isBillingComplete ||
                          (appointmentDateHasPast && !isBillingComplete)
                            ? "Appointment cannot be edited"
                            : "Edit Appointment"
                        }
                        hasArrow
                      >
                        <IconButton
                          aria-label='edit-appointment'
                          onClick={() => {
                            setIsEditMode((m) => !m);
                          }}
                          isDisabled={
                            isBillingComplete ||
                            (appointmentDateHasPast && !isBillingComplete)
                          }
                          icon={<EditIcon />}
                          variant='ghost'
                        />
                      </Tooltip>
                      <IconButton
                        aria-label='close-form'
                        icon={<CloseButton />}
                        variant={"ghost"}
                        onClick={toggleDialog}
                      />
                    </Flex>
                  </Flex>
                ) : (
                  <Text> Add an Appointment</Text>
                )}
              </Flex>
            </ModalHeader>
            <form
              onSubmit={handleSubmit(handleFormSubmit, (e) => {
                console.log("form invalid", e);
              })}
            >
              <ModalBody>
                <VStack spacing={4}>
                  <FormControl isDisabled={disableFormActions}>
                    <FormLabel>Date</FormLabel>
                    {editMode ? (
                      <Input
                        type='datetime-local'
                        value={localStartDateTime}
                        onChange={(e) => setLocalStartDateTime(e.target.value)}
                      />
                    ) : (
                      <Text>
                        {DateTime.fromJSDate(
                          new Date(localStartDateTime)
                        ).toFormat("MM/dd/yy - h:mma") +
                          (appointment
                            ? ` - ${DateTime.fromJSDate(
                                new Date(localStartDateTime)
                              )
                                .plus({ minutes: appointment.length })
                                .toFormat("h:mma")}`
                            : "")}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl isDisabled={disableFormActions}>
                    {editMode ? (
                      <FormLabel display={"flex"} alignItems={"flex-start"}>
                        Duration&nbsp;
                        <HoverHelp
                          label={"Duration in minutes"}
                          h={2.5}
                          w={2.5}
                        ></HoverHelp>
                      </FormLabel>
                    ) : (
                      <></>
                    )}
                    {editMode ? (
                      <Controller
                        name='length'
                        control={control}
                        render={({ field }) => (
                          <Input type='number' {...field} />
                        )}
                      />
                    ) : (
                      // <Text>{watch("length")}</Text>
                      <></>
                    )}
                  </FormControl>
                  <FormControl isDisabled={disableFormActions}>
                    <FormLabel>Pharmacist</FormLabel>
                    {editMode ? (
                      <Controller
                        name='pharmacistId'
                        control={control}
                        render={({ field }) => (
                          <Select {...field} placeholder='Select a Pharmacist'>
                            {webUsers
                              .filter((u) => u.userType === UserType.Pharmacist)
                              .map((wu) => (
                                <option
                                  key={`webUser-${wu.uid}`}
                                  value={wu.uid}
                                >
                                  {wu.name.first + " " + wu.name.last}
                                </option>
                              ))}
                          </Select>
                        )}
                      />
                    ) : (
                      <Text>
                        {watch("pharmacistName.first") +
                          " " +
                          watch("pharmacistName.last")}
                      </Text>
                    )}
                  </FormControl>
                  {!appointment?.id && (
                    <Box w={"full"}>
                      <FormLabel>Patient</FormLabel>
                      <IndexSearch
                        searchIndex='clients'
                        w={"full"}
                        // inputLabel="Patient"
                        filters={
                          currentUser?.placeBasedCareProvId
                            ? `placeBasedCareProvId:${currentUser.placeBasedCareProvId}`
                            : ""
                        }
                        placeholderText='Search by patient name'
                        prefilledText={
                          appointment?.clientName && appointment?.clientId
                            ? `${appointment.clientName.first} ${appointment.clientName.last}`
                            : ""
                        }
                        hitComponent={({ item }: any) => {
                          return (
                            <VStack
                              key={`client-${item.id}`}
                              spacing={0}
                              w={"full"}
                              alignItems={"flex-start"}
                              cursor={"pointer"}
                              // h={"4rem"}
                            >
                              <b>{item.label}</b>
                              <HStack
                                align={"center"}
                                justify={"space-between"}
                              >
                                <Text fontSize={"normal"}>{item.email}</Text>
                                <Text fontSize={"normal"}>
                                  {item.phoneNumber}
                                </Text>
                              </HStack>
                            </VStack>
                          );
                        }}
                        transformItems={customTransformItems}
                        onResultClick={handlePatientSearchResultClick}
                        onClearSelection={() => {
                          setValue("clientId", "");
                          setValue("clientName", {
                            first: "",
                            last: "",
                            display: ""
                          } as IUserNameData);
                        }}
                      />
                      {clientUser && clientUser.prefApptTimes && (
                        <Card
                          variant={"outline"}
                          display={"flex"}
                          flexDir={"row"}
                          p={3}
                          mt={1}
                        >
                          <Text w={"50%"} as={"b"}>
                            Preferred Appointment Times
                          </Text>
                          <VStack spacing={0} align={"flex-end"} w={"50%"}>
                            {clientUser.prefApptTimes.length === 0 ? (
                              <Text as={"i"} fontSize={"normal"}>
                                No preferred times set
                              </Text>
                            ) : (
                              clientUser.prefApptTimes.map((pat, i) => (
                                <Text as={"i"} key={`pref-appt-time-${i}`}>
                                  {pat}
                                </Text>
                              ))
                            )}
                          </VStack>
                        </Card>
                      )}
                    </Box>
                  )}
                  <Box w={"full"}>
                    <FormLabel>Barber</FormLabel>
                    {editMode ? (
                      <IndexSearch
                        w={"full"}
                        searchIndex='barbers'
                        // inputLabel="Barber"
                        filters={`stripeStatus:TransfersEnabled AND placeBasedCareProvIds:${
                          currentUser?.placeBasedCareProvId ?? ""
                        }`}
                        prefilledText={
                          appointment?.barberName && appointment?.barberId
                            ? `${appointment.barberName.first} ${appointment.barberName.last}`
                            : ""
                        }
                        placeholderText='Search by barber name'
                        transformItems={customTransformItems}
                        onResultClick={handleBarberSearchResultClick}
                        onClearSelection={() => {
                          setBarbershopOptions([]);
                        }}
                      />
                    ) : (
                      <Text>
                        {watch("barberName.first") +
                          " " +
                          watch("barberName.last")}
                      </Text>
                    )}
                  </Box>
                  <FormControl>
                    <FormLabel>Barbershop</FormLabel>
                    {editMode ? (
                      <Controller
                        name='barbershopId'
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder='Select a barbershop'
                            isDisabled={
                              !appointment.barbershopId &&
                              barbershopOptions?.length === 0
                            }
                            {...field}
                          >
                            {barbershopOptions?.map((b) => (
                              <option key={`barbershop-${b.id}`} value={b.id}>
                                {b.businessName}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                    ) : (
                      <Text>{watch("barbershopName")}</Text>
                    )}
                  </FormControl>
                  {!editMode && (
                    <FormControl
                      isDisabled={
                        lastApptStatus === AppointmentStatus.Canceled ||
                        lastApptStatus === AppointmentStatus.NoShow ||
                        lastApptStatus === AppointmentStatus.Completed
                      }
                    >
                      <FormLabel>Appointment Confirmation Status</FormLabel>
                      <Select
                        // value={lastApptStatus}
                        {...register("currentStatus")}
                        onChange={updateApptStatus}
                      >
                        <option value={AppointmentStatus.New}>
                          {"Unconfirmed"}
                        </option>
                        <option value={AppointmentStatus.Confirmed}>
                          {AppointmentStatus.Confirmed}
                        </option>
                        <option
                          value={AppointmentStatus.Canceled}
                          disabled={isAfterApptStart}
                        >
                          {startCase(AppointmentStatus.Canceled)}
                        </option>
                        <option
                          hidden={true}
                          value={AppointmentStatus.LateCanceled}
                          disabled={isAfterApptStart}
                        >
                          {startCase(AppointmentStatus.LateCanceled)}
                        </option>
                        <option value={AppointmentStatus.NoShow}>
                          {startCase(AppointmentStatus.NoShow)}
                        </option>
                        <option
                          value={AppointmentStatus.Completed}
                          hidden={true}
                        >
                          {startCase(AppointmentStatus.Completed)}
                        </option>
                      </Select>
                    </FormControl>
                  )}
                </VStack>
              </ModalBody>
              <ModalFooter
                display='flex'
                justifyContent={"flex-end"}
                alignItems={"flex-start"}
                flexDir={"column"}
              >
                {!editMode && isExistingAppointment ? (
                  <HStack
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    w={"full"}
                  >
                    <Tooltip
                      label={
                        // hasBillableEvent ||
                        isBillingComplete
                          ? "Billing has already been submitted"
                          : !canSubmitServicePayment
                          ? "Cannot submit payment for an incomplete appointment"
                          : "Process payment"
                      }
                      hasArrow
                    >
                      <Button
                        isDisabled={
                          // hasBillableEvent ||
                          isBillingComplete || !canSubmitServicePayment
                        }
                        colorScheme='blue'
                        variant='outline'
                        onClick={() => {
                          if (!isBillingComplete) {
                            setActionType("Completed");
                            actionDisclosure.onToggle();
                          }
                        }}
                      >
                        Submit Payment
                      </Button>
                    </Tooltip>
                    {!editMode && (
                      <Tooltip
                        label={
                          lastApptStatus !== AppointmentStatus.Confirmed
                            ? "Appointment must have a status of confirmed"
                            : "Start Patient Appointment"
                        }
                      >
                        <Button
                          onClick={() =>
                            startClientAppointment(appointment.clientId)
                          }
                          colorScheme={"green"}
                          isDisabled={
                            appointment?.currentStatus
                              ? appointment.currentStatus !==
                                AppointmentStatus.Confirmed
                              : appointment?.statusChanges[
                                  appointment.statusChanges?.length - 1
                                ].status !== AppointmentStatus.Confirmed
                          }
                        >
                          Start Appointment
                        </Button>
                      </Tooltip>
                    )}
                  </HStack>
                ) : (
                  editMode && (
                    <HStack
                      w={"full"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Button variant='ghost' onClick={toggleDialog}>
                        Cancel
                      </Button>
                      <Button colorScheme='blue' type='submit'>
                        {loading ? <Spinner /> : "Save"}
                      </Button>
                    </HStack>
                  )
                )}
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  }
);

export default SchedulerAppointmentForm;

const AppointmentActionDialog = ({
  action,
  appointment,
  isOpen,
  onClose,
  onConfirm
}: {
  action: "Completed" | "No-show" | "Cancelled" | undefined;
  appointment: IAppointmentForm;
  startClientAppointment: (clientId: string) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const toast = useToast();
  const { currentUser } = useCurrentUser();
  const cancelRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { billableData } = useBillableEvent(appointment?.billableEventId ?? "");

  const confirmAction = async () => {
    if (!appointment || !appointment?.id) return;
    setLoading(true);
    const barberService = new BarberUserService();
    const barber = await barberService.getBarberUser(appointment.barberId);
    if (!barber) {
      return;
    }
    const { cancelWindow } = barber;
    const isLateCancel = cancelWindow
      ? DateTime.fromJSDate(appointment.startDateTime).minus({
          days: cancelWindow
        }) < DateTime.now()
      : false;
    const appointmentService = new AppointmentService();
    const updatedAppointment = new Appointment({
      ...appointment,
      date: appointment.startDateTime,
      id: appointment.id!
    });
    let statusDetails: string = "";
    let newStatus: AppointmentStatus;
    switch (action) {
      case "Completed":
        statusDetails = "Appointment Started";
        newStatus = AppointmentStatus.Completed;
        break;
      case "Cancelled":
        statusDetails = "Appointment cancelled";
        newStatus = isLateCancel
          ? AppointmentStatus.LateCanceled
          : AppointmentStatus.Canceled;
        break;
      case "No-show":
        statusDetails = "Client no-show";
        newStatus = AppointmentStatus.NoShow;
        break;
      default:
        newStatus = AppointmentStatus.Unknown;
        break;
    }
    const newStatusChanges = [...updatedAppointment.statusChanges];
    newStatusChanges.push(
      new AppointmentStatusChange({
        status: newStatus,
        date: new Date(),
        editorId: currentUser!.uid,
        editorType: currentUser!.userType,
        details: statusDetails
      })
    );
    updatedAppointment.statusChanges = newStatusChanges;
    updatedAppointment.currentStatus = newStatus;
    await appointmentService.updateAppointment(updatedAppointment);
    // create billableEvent and billableItem, then submit charge
    await BillingTransactionService.submitToProgramManager({
      appointmentId: appointment!.id,
      placeBasedCareProvId: currentUser!.placeBasedCareProvId ?? "",
      statusChange:
        action === "Completed"
          ? AppointmentStatus.Completed
          : action === "Cancelled"
          ? AppointmentStatus.Canceled
          : action === "No-show"
          ? AppointmentStatus.NoShow
          : AppointmentStatus.Unknown,
      editorId: currentUser!.uid
    })
      .then(() => {
        toast({
          status: "success",
          description:
            action === "Completed"
              ? "Payment submitted"
              : "Payment request submitted"
        });
        // closeScheduleForm();
        onConfirm();
        onClose();
      })
      .catch((err) => {
        toast({ status: "error", description: err.message });
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
    // if (action === "Completed") {
    //   // navigate to patientDetail and careplan
    //   await startClientAppointment(appointment.clientId).finally(() => {
    //     console.log("CLOSE ACTION DIALOG");
    //     // setLoading(false);
    //     // onClose();
    //   });
    //   // navigate(`/patient/${appointment.clientId}`);
    // }
  };

  // const handleDeleteAppointment = async () => {
  //   if (!appointment || !appointment?.id) return;
  //   const appointmentService = new AppointmentService();
  //   const updatedAppointment = new Appointment({
  //     ...appointment,
  //     date: appointment.startDateTime,
  //     id: appointment.id!
  //   });
  //   const newStatusChanges = [...updatedAppointment.statusChanges];
  //   newStatusChanges.push(
  //     new AppointmentStatusChange({
  //       status: AppointmentStatus.Canceled,
  //       date: new Date(),
  //       editorId: currentUser!.uid,
  //       editorType: currentUser!.userType,
  //       details: "Appointment Deletion"
  //     })
  //   );
  //   updatedAppointment.statusChanges = newStatusChanges;
  //   await appointmentService.updateAppointment(updatedAppointment);
  //   // create billableEvent and billableItem, then submit charge
  //   await AppointmentTransactionService.createBillableFromApptStatus({
  //     appointmentId: appointment!.id,
  //     placeBasedCareProvId: currentUser!.placeBasedCareProvId ?? "",
  //     statusChange: AppointmentStatus.Canceled,
  //     editorId: currentUser!.uid,
  //     approveImmediately: false
  //   });
  // };

  const dialogHeader =
    action === "Completed"
      ? "Submit Payment"
      : action === "Cancelled"
      ? "Cancel Appointment"
      : action === "No-show"
      ? "Client No-show"
      : // : action === "Delete"
        // ? "Delete appointment"
        "[ERROR]";
  const dialogBody =
    action === "Completed"
      ? billableData?.billableItems && billableData.billableItems.length > 1
        ? "Please confirm that the patient has received CHW services from the barber."
        : "Submit payment for the patient's haircut.  "
      : action === "Cancelled"
      ? "Mark this appointment as cancelled and submit barber's cancellation payment to the Case Manager.  "
      : action === "No-show"
      ? "Mark this appointment as no-show and submit barber's no-show payment to the Case Manager.  "
      : // : action === "Delete"
        // ? "Delete this appointment.  Cancellation payment will be submitted for approval to Case Manager"
        "[ERROR]";
  const isEarlyStart =
    DateTime.fromJSDate(appointment?.startDateTime) > DateTime.now();
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {dialogHeader}
          </AlertDialogHeader>

          <AlertDialogBody>
            {dialogBody}{" "}
            {isEarlyStart
              ? "You are submitting payment before the appointment start time, are you sure you want to continue?  "
              : ""}{" "}
            You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme='blue'
              onClick={confirmAction}
              ml={3}
              isDisabled={loading}
            >
              {!loading ? "Confirm" : <Spinner />}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
