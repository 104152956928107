import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  Text,
  HStack,
  VStack
} from "@chakra-ui/react";
import useCurrentUser from "@/hooks/useCurrentUser";
import useAuthentication from "@/hooks/useAuthentication";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import changePasswordSchema from "@/resolvers/changePassword";
import { CheckCircleIcon } from "@chakra-ui/icons";

interface IPasswordChangeData {
  password: string;
  confirmPassword: string;
}

const ChangePasswordForm = () => {
  const { currentUser } = useCurrentUser();
  const { isOpen, onClose } = useDisclosure({
    isOpen: currentUser?.pwdNeedsReset
  });
  const toast = useToast();
  const [actionCompleted, setActionCompleted] = useState(false);
  const { changePassword } = useAuthentication();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch
  } = useForm<IPasswordChangeData>({
    defaultValues: {
      password: "",
      confirmPassword: ""
    },
    mode: "onChange",
    resolver: yupResolver(changePasswordSchema)
  });

  const handleModalClose = () => {
    if (!currentUser?.pwdNeedsReset) {
      onClose();
    } else if (!actionCompleted) {
      toast({
        status: "warning",
        description: "You must change your password"
      });
    }
  };

  const submitForm = async (data: IPasswordChangeData) => {
    const { password } = data;
    await changePassword(password, currentUser?.pwdNeedsReset).then(() => {
      setActionCompleted(true);
      toast({ status: "success", description: "Password changed" });
      onClose();
    });
  };

  const providedPassword = watch("password");

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose} useInert>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(submitForm)}>
          <ModalHeader>Change password</ModalHeader>
          <ModalBody>
            <FormControl isInvalid={!!errors.password}>
              <FormLabel>New password</FormLabel>
              <Input type={"password"} {...register("password")} />
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.confirmPassword} mt={2}>
              <FormLabel>Re-enter new password</FormLabel>
              <Input type={"password"} {...register("confirmPassword")} />
              <FormErrorMessage>
                {errors.confirmPassword && errors.confirmPassword.message}
              </FormErrorMessage>
            </FormControl>
            <VStack
              alignItems={"flex-start"}
              spacing={0}
              mt={2}
              fontSize={"sm"}
            >
              <HStack
                color={checkLength(providedPassword) ? "green.500" : "gray.500"}
              >
                <CheckCircleIcon />
                <Text>Must be at least 12 characters long</Text>
              </HStack>
              <HStack
                color={
                  checkLowercase(providedPassword) ? "green.500" : "gray.500"
                }
              >
                <CheckCircleIcon />
                <Text>Must contain a lower-case letter</Text>
              </HStack>
              <HStack
                color={
                  checkUppercase(providedPassword) ? "green.500" : "gray.500"
                }
              >
                <CheckCircleIcon />
                <Text>Must contain an upper-case letter</Text>
              </HStack>
              <HStack
                color={checkNumber(providedPassword) ? "green.500" : "gray.500"}
              >
                <CheckCircleIcon />
                <Text>Must contain a number</Text>
              </HStack>
              <HStack
                color={
                  checkSpecialCharacter(providedPassword)
                    ? "green.500"
                    : "gray.500"
                }
              >
                <CheckCircleIcon />
                <Text>Must contain a special character</Text>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter>
            {!currentUser?.pwdNeedsReset && (
              <Button onClick={onClose}>Cancel</Button>
            )}
            <Button type='submit' colorScheme='blue' isDisabled={!isValid}>
              Submit
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordForm;

const checkLength = (password: string) => {
  const lengthRegex = /^[A-Za-z\d@$!%*?&#]{12,64}$/;
  return lengthRegex.test(password);
};

const checkLowercase = (password: string) => {
  const lowercaseRegex = /(?=.*[a-z])/;
  return lowercaseRegex.test(password);
};

const checkUppercase = (password: string) => {
  const uppercaseRegex = /(?=.*[A-Z])/;
  return uppercaseRegex.test(password);
};

const checkNumber = (password: string) => {
  const numberRegex = /(?=.*\d)/;
  return numberRegex.test(password);
};

const checkSpecialCharacter = (password: string) => {
  const specialCharRegex = /(?=.*[@$!%*?&#])/;
  return specialCharRegex.test(password);
};
