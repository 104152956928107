import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: import.meta.env.VITE_DATADOG_APP_ID ?? "",
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN ?? "",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "us5.datadoghq.com",
  service: "oben-web",
  env: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input"
});

const addAction = (actionName: string, data?: any) => {
  datadogRum.addAction(actionName, {
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    ...data
  });
};

export { datadogRum, addAction };
