import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import usePatients from "@/hooks/usePatients";
import { DateTime } from "luxon";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineChevronRight } from "react-icons/md";
import { ClientUser } from "@oben-core-web/models/client-user";
import { ColDef } from "ag-grid-community";
import usePlaceBasedCareProvider from "@/hooks/usePlaceBasedCareProvider";

interface IPatientTable {
  selectPatient: React.Dispatch<React.SetStateAction<ClientUser | undefined>>;
}

const PatientTable = React.memo(
  ({ selectPatient }: IPatientTable) => {
    const { placeBasedCareProvider } = usePlaceBasedCareProvider();
    const { clientUsers } = usePatients(placeBasedCareProvider?.id ?? "");
    const navigation = useNavigate();

    const patients = clientUsers.map((cu, i) => ({
      ...cu,
      id: cu.uid,
      ...(i === 0 ? { className: "nav-to-patient-detail" } : {})
    }));
    const navToPatient = (patientId: string) => {
      navigation("/patient/" + patientId);
    };

    const LinkToDetail = (params: any) => {
      return (
        <Link to={`/patient/${params.data.id}`}>
          <MdOutlineChevronRight
            className={params.data.className}
            size={24}
            style={{ alignSelf: "center", height: "100%" }}
          />
        </Link>
      );
    };

    const columnHeaders: ColDef[] = useMemo(
      () => [
        {
          field: "name",
          headerName: "Patient",
          valueGetter: (row) => row.data.name.fullName ?? "",
          flex: 1
        },
        {
          field: "dob",
          headerName: "Date of Birth",
          valueGetter: (row) => {
            return row.data.dob
              ? DateTime.fromJSDate(row.data.dob).toUTC().toFormat("DD")
              : "";
          },
          flex: 1
        },
        {
          field: "registrationDate",
          valueGetter: (row) => {
            return row.data.registrationDate
              ? DateTime.fromJSDate(row.data.registrationDate).toFormat("D")
              : "";
          },
          flex: 1
        },
        // { field: "lastAppt" },
        {
          field: "lastBp",
          flex: 1,
          valueGetter: (row) =>
            row.data.currentBpReading
              ? row.data.currentBpReading.systolic +
                "/" +
                row.data.currentBpReading.diastolic
              : ""
        },
        // { field: "engagementStatus" },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: LinkToDetail,
          sortable: false,
          filter: false
        }
      ],
      []
    );

    const handleRowSelected = (row: any) => {
      selectPatient(row.data);
    };

    const handleRowDblClick = (row: any) =>
      row.data && navToPatient(row.data.id);

    return (
      <div
        className='ag-theme-quartz'
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          className='patient-table'
          rowData={patients}
          columnDefs={columnHeaders as any}
          rowSelection='single'
          // onRowSelected={handleRowSelected}
          onRowDoubleClicked={handleRowDblClick}
          onRowClicked={handleRowSelected}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.selectPatient === nextProps.selectPatient;
  }
);

export default PatientTable;
