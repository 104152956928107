import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Card,
  Button,
  Input,
  FormControl,
  FormErrorMessage,
  Center,
  VStack,
  Spinner,
  useToast
} from "@chakra-ui/react";
import { useState } from "react";
import loginSchema from "@resolvers/login";
import useAuthentication from "@/hooks/useAuthentication";
import ObenLoginLogo from "@/assets/ObenLoginLogo";
import { FirebaseError } from "firebase/app";
import { datadogRum } from "../../../datadog";

interface LoginFormInputs {
  email: string;
  password: string;
}

const Login = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { login } = useAuthentication();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginSchema)
  });

  const onSubmit = async (data: LoginFormInputs) => {
    setLoading(true);
    await login(data)
      .catch((err) => {
        if (err instanceof FirebaseError) {
          const { code } = err;
          switch (code) {
            case "auth/user-not-found":
            case "auth/wrong-password":
              datadogRum.addError(err);
              toast({
                status: "error",
                description: "Invalid e-mail or password"
              });
              break;
            default:
              toast({ status: "error", description: "Something went wrong" });
              break;
          }
        } else {
          toast({ status: "error", description: "An error has occurred." });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Center w={"full"} height={"100vh"} bg={"gray.50"}>
      <Card
        bg={"white"}
        variant={"outline"}
        p={8}
        w={"30%"}
        alignItems={"center"}
      >
        <ObenLoginLogo />
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <VStack
            spacing={4}
            justifyContent={"center"}
            alignItems={"center"}
            w={"full"}
          >
            <FormControl isInvalid={!!errors.email}>
              {/* <FormLabel>Email</FormLabel> */}
              <Input placeholder='Email' type='email' {...register("email")} />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              {/* <FormLabel>Password</FormLabel> */}
              <Input
                placeholder='Password'
                type='password'
                {...register("password")}
              />
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <Button type='submit' colorScheme='teal' mt={4} w={"50%"}>
              {!loading ? "Login" : <Spinner />}
            </Button>
          </VStack>
        </form>
      </Card>
    </Center>
  );
};

export default Login;
