import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { ClientUser } from "@oben-core-web/models/client-user";
import { ScreeningClient } from "@oben-core-web/models/screening-client";
import { DateTime } from "luxon";
import { Outreach, OutreachStatus } from "@oben-core-web/models/outreach";
import { startCase } from "lodash";
import { IconButton } from "@chakra-ui/react";
import { MdPhone } from "react-icons/md";
import { parsePhoneNumber } from "@/lib/parseUtils";

interface IPatientEnrollmentTable {
  enrollmentPatients: {
    client: ClientUser;
    enrollmentOutreach: Outreach | null;
  }[];
  setEnrollmentClient: React.Dispatch<
    React.SetStateAction<ClientUser | ScreeningClient | undefined>
  >;
}

const PatientEnrollmentTable = ({
  enrollmentPatients,
  setEnrollmentClient
}: IPatientEnrollmentTable) => {
  const patientData = enrollmentPatients.map((p) => ({
    ...p,
    name: p.client.name ? p.client.name.fullName : "Unnamed",
    dob: p.client.dob,
    phone: p.client.phoneNumber ? parsePhoneNumber(p.client.phoneNumber) : "",
    registrationDate: p.client.registrationDate,
    // email: p.client.email,
    lastBpReading:
      p.client.currentBpReading?.systolic +
      "/" +
      p.client.currentBpReading?.diastolic,
    outreachStatus: p.enrollmentOutreach?.status
      ? p.enrollmentOutreach.status !== OutreachStatus.Unknown
        ? startCase(p.enrollmentOutreach?.status)
        : "New"
      : "New",
    lastOutreachAttemptStatus: startCase(
      p.enrollmentOutreach?.attempts[p.enrollmentOutreach?.attempts.length - 1]
        ?.result
    )
  }));

  const columnHeaders: ColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "phone",
      headerName: "Phone",
      valueFormatter: ({ value }) => {
        const cleaned = ("" + value).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return value;
      },
      flex: 0.5
    },
    // { field: "email", headerName: "Email", flex: 1 },
    {
      field: "registrationDate",
      headerName: "Registration Date",
      valueFormatter: ({ value }) => {
        return DateTime.fromJSDate(value).toFormat("D");
      },
      flex: 0.5
    },
    { field: "lastBpReading", headerName: "Last BP Reading", flex: 0.5 },
    {
      field: "outreachStatus",
      headerName: "Outreach Status",
      flex: 0.5
    },
    {
      field: "lastOutreachAttemptStatus",
      headerName: "Last Outreach Attempt Result",
      flex: 1
    },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer: (row: any) => {
        return (
          <IconButton
            aria-label={`patient-enrollment-btn-${row.data.uid}`}
            onClick={() => {
              setEnrollmentClient(
                row.data.client.uid
                  ? ClientUser.fromMap(row.data.client.uid, row.data.client)
                  : ScreeningClient.fromMap(row.data.id, row.data)
              );
            }}
            rounded={"xl"}
            variant={"ghost"}
            size={"xs"}
            // colorScheme='teal'
            icon={<MdPhone size={20} />}
          />
        );
      },
      flex: 0.5
    }
  ];

  return (
    <div className='ag-theme-quartz' style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        className='enrollment-table'
        rowData={patientData}
        columnDefs={columnHeaders}
        // onRowClicked={(clickEvent) => {
        //   console.log({ data: clickEvent.data });
        //   setEnrollmentClient(
        //     clickEvent.data.client.uid
        //       ? ClientUser.fromMap(
        //           clickEvent.data.client.uid,
        //           clickEvent.data.client
        //         )
        //       : ScreeningClient.fromMap(clickEvent.data.id, clickEvent.data)
        //   );
        // }}
      />
    </div>
  );
};

export default PatientEnrollmentTable;
