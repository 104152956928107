import PatientProfile from "../molecules/PatientProfile";
import useClientUserSubscription from "@/hooks/useClientUserSubscription";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Center,
  HStack,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import CarePlanWizard from "../organisms/CarePlanWizard";
import useClientCarePlans from "@/hooks/useClientCarePlans";
import { PlanStatus } from "@oben-core-web/models/care-plan";
import { CarePlan } from "@oben-core-web/models/care-plan";
import { CarePlanService } from "@oben-core-web/services/care-plan-service";
import ClientNotes from "../organisms/ClientNotes";
import CarePlanCard from "../organisms/CarePlanCard";
import { ClientUserService } from "@oben-core-web/services/client-user-service";
import BPChart from "../molecules/BPChart";
import MedicationChart from "../molecules/MedicationChart";
import MedAdherenceChart from "../molecules/MedAdherenceChart";
import PatientRecommendationTable from "../molecules/PatientRecommendationTable";
import usePatientAppointments from "@/hooks/usePatientAppointments";
import { AppointmentStatus } from "@oben-core-web/models/appointment-status-change";
import { DateTime } from "luxon";
import { ClientUser } from "@oben-core-web/models/client-user";
import { Appointment } from "@oben-core-web/models/appointment";
import PatientAppointment from "../organisms/PatientAppointment";

// TODO: add current bp reading -- add where it was recorded too
const PatientDetail = () => {
  const { id } = useParams();
  const [newCarePlanId, setNewCarePlanId] = useState<string>();
  const { clientUser } = useClientUserSubscription(id);
  const [showMedAdherences, setShowMedAdherences] = useState(false);
  const { isOpen: patientApptIsOpen, onToggle: togglePatientAppt } =
    useDisclosure();
  const [apptData, setApptData] = useState<{
    patient: ClientUser;
    appointment: Appointment;
  }>();
  const { isOpen, onToggle } = useDisclosure();
  const { currentCarePlan } = useClientCarePlans(id);
  const { appointments } = usePatientAppointments({
    clientId: id ?? "",
    statuses: [AppointmentStatus.Confirmed]
  });
  const createDraftCarePlan = async () => {
    if (!id || !clientUser) return;
    const carePlanService = new CarePlanService();
    const carePlan = new CarePlan({
      modelId: "",
      clientId: id,
      createdDate: new Date(),
      status: PlanStatus.Draft,
      bpGoals: [],
      diagnosis: "",
      history: ""
    });
    const draftCarePlan = await carePlanService
      .addCarePlan(carePlan)
      .then((carePlanId) => {
        const clientUserService = new ClientUserService();
        clientUser.currentCarePlanId = carePlanId;
        clientUserService.updateClientUser(clientUser);
        return carePlanId;
      });
    setNewCarePlanId(draftCarePlan);
    onToggle();
  };
  const startAppointment = (appointment: Appointment) => {
    if (clientUser) {
      setApptData({ patient: clientUser, appointment });
      togglePatientAppt();
    }
  };
  return (
    <Box>
      {clientUser && (
        <>
          {isOpen ? (
            <CarePlanWizard
              patient={clientUser}
              exitWizard={onToggle}
              // isEditMode={currentCarePlan.status === PlanStatus.Current}
              carePlanId={currentCarePlan?.modelId ?? newCarePlanId}
            />
          ) : (
            <Box>
              {apptData?.patient && apptData.appointment && (
                <PatientAppointment
                  clientUser={apptData.patient}
                  appointmentStarted={patientApptIsOpen}
                  toggleApptStarted={togglePatientAppt}
                  appointment={apptData.appointment}
                  exitWizard={() => {
                    setApptData(undefined);
                    togglePatientAppt();
                  }}
                />
              )}
              <PatientProfile patient={clientUser} />
              {appointments.length > 0 &&
                appointments.every((a) => !!a.date) && (
                  <Card alignItems={"flex-start"} gap={2} p={4} w={"full"}>
                    Patient Appointments
                    {appointments.map((appt) => (
                      <HStack
                        w={"full"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        border={"1px solid lightgray"}
                        rounded={"md"}
                        p={2}
                        key={`appt-${appt.id}`}
                      >
                        <Text as='b' display={"flex"}>
                          {DateTime.fromJSDate(appt.date!).toFormat(
                            "M/d/yy - h:mma @"
                          )}
                          &nbsp;
                          <Text fontWeight={"normal"}>
                            {appt.barbershopName}
                          </Text>
                        </Text>
                        <Button
                          colorScheme='blue'
                          onClick={() => {
                            startAppointment(appt);
                          }}
                          size={"sm"}
                        >
                          Start Appointment
                        </Button>
                      </HStack>
                    ))}
                  </Card>
                )}
              <Tabs
                mt={4}
                isLazy
                isFitted
                variant='enclosed-colored'
                bg={"white"}
                borderRadius={"lg"}
                className='patient-profile-tabs'
              >
                <TabList mb='1em'>
                  <Tab>Charts</Tab>
                  <Tab className='care-plan-tab'>Care Plan</Tab>
                  <Tab className='client-notes-tab'>Client Notes</Tab>
                  <Tab className='chw-recommendations'>
                    Recommendation Letters
                  </Tab>
                </TabList>
                <TabPanels>
                  {id && (
                    <TabPanel>
                      <BPChart
                        patientId={id}
                        height={400}
                        width={"100%"}
                        canEditGoal={false}
                      />
                      <Box mt={2}>
                        {showMedAdherences ? (
                          <MedAdherenceChart
                            patientId={id}
                            isEditable={false}
                            toggleChart={() => setShowMedAdherences((c) => !c)}
                            height={250}
                          />
                        ) : (
                          <MedicationChart
                            patientId={id}
                            isEditable={false}
                            toggleChart={() => setShowMedAdherences((c) => !c)}
                            height={250}
                          />
                        )}
                      </Box>
                    </TabPanel>
                  )}
                  <TabPanel className='care-plan-tab'>
                    {currentCarePlan ? (
                      <CarePlanCard
                        clientId={clientUser.uid}
                        openCarePlanWizard={onToggle}
                      />
                    ) : (
                      <SimpleGrid
                        columns={2}
                        spacing={52}
                        justifyContent={"center"}
                        alignItems={"center"}
                        h={"25rem"}
                        px={12}
                      >
                        <Center>
                          <Text fontSize={"xx-large"}>
                            It's easy to get your blood pressure on track
                          </Text>
                        </Center>
                        <VStack
                          justifyContent={"center"}
                          textAlign={"start"}
                          alignItems={"flex-start"}
                          h={"full"}
                        >
                          <b style={{ fontSize: "1.25rem" }}>
                            1. We will talk about your numbers
                          </b>
                          <b style={{ fontSize: "1.25rem" }}>
                            2. Create a plan and set goals
                          </b>
                          <b style={{ fontSize: "1.25rem" }}>
                            3. Share the plan and schedule follow up
                          </b>
                          <Button
                            alignSelf={"center"}
                            onClick={createDraftCarePlan} // Clicking this button should create a draft careplan if one doesn't already exist
                            size={"lg"}
                            w={"full"}
                            mt={8}
                            colorScheme='blue'
                          >
                            Get Started
                          </Button>
                        </VStack>
                      </SimpleGrid>
                    )}
                  </TabPanel>
                  <TabPanel>
                    <ClientNotes clientId={clientUser.uid} />
                  </TabPanel>
                  <TabPanel>
                    <PatientRecommendationTable
                      patientId={clientUser.uid}
                      h={"20rem"}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default PatientDetail;
