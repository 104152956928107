import { getAnalytics, logEvent } from "firebase/analytics";
import { addAction } from '../../datadog'

export enum GaLogType {
  CountCollection = "CountCollection",
  CountError = "CountError",
  ReadCollection = "ReadCollection",
  ReadDocument = "ReadDocument",
  ReadError = "ReadError",
  AddDocument = "AddDocument",
  AddError = "AddError",
  UpdateDocument = "UpdateDocument",
  UpdateError = "UpdateError",
  DeleteDocument = "DeleteDocument",
  DeleteError = "DeleteError",
}

const fbAnalytics = getAnalytics();

export class GaLog {
  static countCollection(path: string, count: number, {isSubscription = false}: {isSubscription?: boolean} = {}): void {
    logEvent(fbAnalytics, GaLogType.CountCollection, { "path": path, "count": count, "isSubscription": isSubscription });
  }

  static countError(path: string, error: any, {isSubscription = false}: {isSubscription?: boolean} = {}): void {
    logEvent(fbAnalytics, GaLogType.CountError, { "path": path, "error": error, "isSubscription": isSubscription });
  }

  static readCollection(path: string, count: number, {isSubscription = false}: {isSubscription?: boolean} = {}): void {
    logEvent(fbAnalytics, GaLogType.ReadCollection, { "path": path, "count": count, "isSubscription": isSubscription });
    addAction('Read Collection', {path, isSubscription})
  }
  
  static readDocument(path: string, id: string, {isSubscription = false}: {isSubscription?: boolean} = {}): void {
    logEvent(fbAnalytics, GaLogType.ReadDocument, { "path": path, "id": id, "isSubscription": isSubscription });
    addAction('Read Document', {path, id, isSubscription})
  }
  
  static readError(path: string, error: any, {isSubscription = false}: {isSubscription?: boolean} = {}): void {
    logEvent(fbAnalytics, GaLogType.ReadError, { "path": path, "error": error, "isSubscription": isSubscription });
    addAction('Read error', {path, error, isSubscription})
  }

  static addDocument(path: string, id: string): void {
    logEvent(fbAnalytics, GaLogType.AddDocument, { "path": path, "id": id });
    addAction('Insert Document', {path, id})
  }

  static addError(path: string, error: any): void {
    logEvent(fbAnalytics, GaLogType.AddError, { "path": path, "error": error });
    addAction('Insert Error', {path, error})
  }
  
  static updateDocument(path: string, id: string): void {
    logEvent(fbAnalytics, GaLogType.UpdateDocument, { "path": path, "id": id });
    addAction('Update Document', {path, id})
  }
  
  static updateError(path: string, error: any): void {
    logEvent(fbAnalytics, GaLogType.UpdateError, { "path": path, "error": error });
    addAction('Update Error', {path, error})
  }
  
  static deleteDocument(path: string, id: string): void {
    logEvent(fbAnalytics, GaLogType.DeleteDocument, { "path": path, "id": id });
    addAction('Delete Document', {path, id})
  }
  
  static deleteError(path: string, error: any): void {
    logEvent(fbAnalytics, GaLogType.DeleteError, { "path": path, "error": error });
    addAction('Update Error', {path, error})
  }
}