import {
  SimpleGrid,
  Center,
  VStack,
  Button,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Box,
  useToast
} from "@chakra-ui/react";
import { Appointment } from "@oben-core-web/models/appointment";
import { CarePlan, PlanStatus } from "@oben-core-web/models/care-plan";
import { CarePlanService } from "@oben-core-web/services/care-plan-service";
import { useState } from "react";
import CarePlanCard from "./CarePlanCard";
import CarePlanWizard from "./CarePlanWizard";
import { ClientUser } from "@oben-core-web/models/client-user";
import { ClientUserService } from "@oben-core-web/services/client-user-service";
import NoteDrawer from "./NoteDrawer";
import { AppointmentService } from "@oben-core-web/services/appointment-service";
import {
  AppointmentStatus,
  AppointmentStatusChange
} from "@oben-core-web/models/appointment-status-change";
import useCurrentUser from "@/hooks/useCurrentUser";

interface IPatientAppointment {
  clientUser?: ClientUser;
  appointmentStarted: boolean;
  toggleApptStarted: () => void;
  appointment: Appointment;
  exitWizard: () => void;
}

const PatientAppointment = ({
  clientUser,
  appointment,
  appointmentStarted,
  toggleApptStarted,
  exitWizard
}: IPatientAppointment) => {
  const [carePlanId, setCarePlanId] = useState(clientUser?.currentCarePlanId);
  const { currentUser } = useCurrentUser();
  const toast = useToast();
  const createDraftCarePlan = async () => {
    if (!clientUser) return;
    const carePlanService = new CarePlanService();
    const carePlan = new CarePlan({
      modelId: "",
      clientId: clientUser.uid,
      createdDate: new Date(),
      status: PlanStatus.Draft,
      bpGoals: [],
      diagnosis: "",
      history: ""
    });
    const draftCarePlan = await carePlanService
      .addCarePlan(carePlan)
      .then(async (carePlanId) => {
        const clientUserService = new ClientUserService();
        clientUser.currentCarePlanId = carePlanId;
        await clientUserService.updateClientUser(clientUser);
        return carePlanId;
      });
    setCarePlanId(draftCarePlan);
    // toggleApptStarted();
  };
  if (!appointment || !currentUser) return <></>;
  return (
    <Drawer
      isOpen={appointmentStarted}
      onClose={exitWizard}
      size='full'
      placement='bottom'
      closeOnEsc={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody px={12} overflowY={"auto"} maxH={"100vh"}>
          <NoteDrawer clientId={clientUser?.uid} />
          <DrawerCloseButton pos={"absolute"} />
          {appointmentStarted && clientUser && carePlanId ? (
            <Box position={"relative"}>
              <CarePlanWizard
                patient={clientUser}
                carePlanId={carePlanId}
                exitWizard={() => {
                  exitWizard();
                  toggleApptStarted();
                }}
                onAppointmentComplete={async () => {
                  const appointmentService = new AppointmentService();
                  const requeriedAppt = await appointmentService.getAppointment(
                    appointment.id
                  );
                  if (
                    requeriedAppt.currentStatus !== AppointmentStatus.Completed
                  ) {
                    const newApptStatus = new AppointmentStatusChange({
                      status: AppointmentStatus.Completed,
                      date: new Date(),
                      editorId: currentUser.uid,
                      editorType: currentUser.userType,
                      details: "Pharmacist appointment complete"
                    });
                    requeriedAppt.statusChanges.push(newApptStatus);
                    requeriedAppt.currentStatus = AppointmentStatus.Completed;
                    await appointmentService
                      .updateAppointment(requeriedAppt)
                      .then(() => {
                        toast({
                          status: "success",
                          description: "Appointment completed!"
                        });
                      })
                      .catch((e) => {
                        console.log(
                          "Failed to update completed appointment status",
                          e
                        );
                        toast({
                          status: "error",
                          description:
                            "Failed to update appointment status as completed"
                        });
                      });
                  }
                }}
                skipBillingReminder={true}
              />
            </Box>
          ) : (
            <>
              {clientUser && carePlanId ? (
                <CarePlanCard
                  clientId={clientUser.uid}
                  openCarePlanWizard={toggleApptStarted}
                />
              ) : (
                <SimpleGrid
                  columns={2}
                  spacing={52}
                  justifyContent={"center"}
                  alignItems={"center"}
                  h={"90vh"}
                  px={12}
                >
                  <Center>
                    <Text fontSize={"xx-large"}>
                      It's easy to get your blood pressure on track
                    </Text>
                  </Center>
                  <VStack
                    justifyContent={"center"}
                    textAlign={"start"}
                    alignItems={"flex-start"}
                    h={"full"}
                  >
                    <b style={{ fontSize: "1.25rem" }}>
                      1. We will talk about your numbers
                    </b>
                    <b style={{ fontSize: "1.25rem" }}>
                      2. Create a plan and set goals
                    </b>
                    <b style={{ fontSize: "1.25rem" }}>
                      3. Share the plan and schedule follow up
                    </b>
                    <Button
                      alignSelf={"center"}
                      onClick={createDraftCarePlan}
                      size={"lg"}
                      w={"full"}
                      mt={8}
                      colorScheme='blue'
                    >
                      Get Started
                    </Button>
                  </VStack>
                </SimpleGrid>
              )}
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default PatientAppointment;
